import React from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';

import TextField from '@material-ui/core/TextField';
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const LISTBOX_PADDING = 8; // px

const renderRow = (props) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const Autocomplete = ({
  value,
  onChange,
  label,
  options,
  getOptionLabel = (option) => option,
  groupBy,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <MaterialAutocomplete
      id="autocomplete"
      style={{ width: '100%' }}
      classes={classes}
      disabled={disabled}
      ListboxComponent={ListboxComponent}
      renderGroup={renderGroup}
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={onChange}
      groupBy={groupBy}
      debug
      renderInput={(params) => <TextField {...params} label={label} fullWidth />}
      renderOption={(option) => <Typography noWrap>{getOptionLabel(option)}</Typography>}
    />
  );
};

Autocomplete.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Autocomplete;
