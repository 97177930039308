import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import WarningIcon from '@material-ui/icons/Warning';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import HardwareList from '../../HardwareList';
import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';

import Button from '../../../../../shared/Button';
import Dropzone from '../../../../../shared/dropzone/Dropzone';

export const CmfStep3 = (props) => {
  const {
    classes,
    onClose,
    activeCase,
    updateCase,
    products,
    preopPlan,
    preopRejected,
    // blockFilesRejected,
    caseMaxilla,
    caseLeftMandible,
    caseRightMandible,
    caseGenioplasty,
    fileStatuses,
    fileTypes,
    statuses,
    caseNotifications,
    userId,
  } = props;

  const [preop, setPreop] = useState([]);
  const [maxillaList, setMaxillaList] = useState(caseMaxilla ? caseMaxilla : []);
  const [leftMandibleList, setLeftMandibleList] = useState(caseLeftMandible ? caseLeftMandible : []);
  const [rightMandibleList, setRightMandibleList] = useState(caseRightMandible ? caseRightMandible : []);
  const [genioplastyList, setGenioplastyListList] = useState(caseGenioplasty ? caseGenioplasty : []);

  const requiredDate = activeCase?.date ? moment(activeCase?.date).subtract(7, 'd') : moment();
  const [date, setDate] = useState(requiredDate);

  const handleRemovePreop = (name) => {
    setPreop(preop.filter((file) => file.name !== name));
  };

  // Maxilla

  const handleAddMaxilla = (product) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setMaxillaList(list);
    } else {
      setMaxillaList([...maxillaList, product]);
    }
  };

  const handleRemoveMaxilla = (id) => {
    setMaxillaList(maxillaList.filter((item) => item.id !== id));
  };

  const handleAmountChange = (id, amount) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setMaxillaList(list);
  };

  const handleCategoryChange = (id, category) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setMaxillaList(list);
  };

  const handleChange = (id, product) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setMaxillaList(list);
  };

  // Left Mandible

  const handleAddLeftMandible = (product) => {
    const list = [...leftMandibleList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setLeftMandibleList(list);
    } else {
      setLeftMandibleList([...leftMandibleList, product]);
    }
  };

  const handleRemoveLeftMandible = (id) => {
    setLeftMandibleList(leftMandibleList.filter((item) => item.id !== id));
  };

  const handleAmountLeftMandibleChange = (id, amount) => {
    const list = [...leftMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.amount = amount;
    setLeftMandibleList(list);
  };

  const handleCategoryLeftMandibleChange = (id, category) => {
    const list = [...leftMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.category = category;
    setLeftMandibleList(list);
  };

  const handleLeftMandibleChange = (id, product) => {
    const list = [...leftMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.product = product;
    setLeftMandibleList(list);
  };

  // Right Mandible

  const handleAddRightMandible = (product) => {
    const list = [...rightMandibleList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setRightMandibleList(list);
    } else {
      setRightMandibleList([...rightMandibleList, product]);
    }
  };

  const handleRemoveRightMandible = (id) => {
    setRightMandibleList(rightMandibleList.filter((item) => item.id !== id));
  };

  const handleAmountRightMandibleChange = (id, amount) => {
    const list = [...rightMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.amount = amount;
    setRightMandibleList(list);
  };

  const handleCategoryRightMandibleChange = (id, category) => {
    const list = [...rightMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.category = category;
    setRightMandibleList(list);
  };

  const handleRightMandibleChange = (id, product) => {
    const list = [...rightMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.product = product;
    setRightMandibleList(list);
  };

  // Genioplasty

  const handleAddGenioplasty = (product) => {
    const list = [...genioplastyList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setGenioplastyListList(list);
    } else {
      setGenioplastyListList([...genioplastyList, product]);
    }
  };

  const handleRemoveGenioplasty = (id) => {
    setGenioplastyListList(genioplastyList.filter((item) => item.id !== id));
  };

  const handleAmountGenioplastyChange = (id, amount) => {
    const list = [...genioplastyList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setGenioplastyListList(list);
  };

  const handleCategoryGenioplastyChange = (id, category) => {
    const list = [...genioplastyList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setGenioplastyListList(list);
  };

  const handleGenioplastyChange = (id, product) => {
    const list = [...genioplastyList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setGenioplastyListList(list);
  };

  const handleSubmit = () => {
    const now = moment().format();

    const activity = [
      { ...caseNotifications[3].success, date: now, userId },
      { ...caseNotifications[3].file, date: now, userId },
      ...activeCase.activity,
    ];

    const files = [...activeCase.files];
    const newFiles = [];
    const preopFile = preop[0];

    if (preopFile) {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.preop}_${preopFile.name}`,
        name: preopFile.name,
        type: fileTypes.preop,
        status: fileStatuses.PENDING,
        date: now,
        userId,
      });
      newFiles.push(preopFile);
    }

    const approvedPreop = files.find((file) => file.type === fileTypes.preop && file.status === fileStatuses.APPROVED);

    updateCase(
      {
        ...activeCase,
        step: approvedPreop ? 5 : 4,
        activity,
        maxilla: maxillaList.map((item) => ({
          product: item.product,
          amount: item.amount,
          category: item.category,
        })),
        leftMandible: leftMandibleList.map((item) => ({
          product: item.product,
          amount: item.amount,
          category: item.category,
        })),
        rightMandible: rightMandibleList.map((item) => ({
          product: item.product,
          amount: item.amount,
          category: item.category,
        })),
        genioplasty: genioplastyList.map((item) => ({
          product: item.product,
          amount: item.amount,
          category: item.category,
        })),
        files,
        completionDates: { ...activeCase.completionDates, step3: now },
        completedBy: { ...activeCase.completedBy, step3: userId },
        status: statuses.inProgress,
        notes: {
          ...activeCase.notes,
        },
        requiredDate: date.format(),
      },
      newFiles
    ).then(() => onClose());
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">3</div>
          </div>
          <div className="modal-item-content">
            <div className="m-b-lg">Attach Pre Op Plan</div>
            {activeCase.access ? (
              <div>
                <div className="m-t-lg m-b-md">
                  <div className="secondary font-size-sm m-b-sm">ATTACH PRE-OP PLAN (.pdf)</div>
                  {preopRejected && (
                    <div className="alert warning m-b-md">
                      <div className="d-flex align-center">
                        <WarningIcon color="primary" />
                        <div>Pre-Op plan has been rejected</div>
                      </div>
                      {activeCase.notes && activeCase.notes.step6 && (
                        <div className="m-t-md">Note: {activeCase.notes.step6}</div>
                      )}
                    </div>
                  )}
                  <Dropzone
                    files={preop}
                    onUpload={setPreop}
                    type="pdf"
                    icon="preop"
                    multiple={false}
                    // disabled={blockFilesRejected}
                    onRemove={handleRemovePreop}
                  />
                </div>
                <div className="m-t-lg">
                  <HardwareList
                    classes={classes}
                    hardwareList={maxillaList}
                    products={products}
                    onAdd={handleAddMaxilla}
                    onRemove={handleRemoveMaxilla}
                    onAmountChange={handleAmountChange}
                    onCategoryChange={handleCategoryChange}
                    onChange={handleChange}
                    title="Maxilla"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>
                <div style={{ marginTop: 32, marginBottom: 32 }}>
                  <HardwareList
                    classes={classes}
                    hardwareList={leftMandibleList}
                    products={products}
                    onAdd={handleAddLeftMandible}
                    onRemove={handleRemoveLeftMandible}
                    onAmountChange={handleAmountLeftMandibleChange}
                    onCategoryChange={handleCategoryLeftMandibleChange}
                    onChange={handleLeftMandibleChange}
                    title="Left Mandible"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>
                <div style={{ marginTop: 32, marginBottom: 32 }}>
                  <HardwareList
                    classes={classes}
                    hardwareList={rightMandibleList}
                    products={products}
                    onAdd={handleAddRightMandible}
                    onRemove={handleRemoveRightMandible}
                    onAmountChange={handleAmountRightMandibleChange}
                    onCategoryChange={handleCategoryRightMandibleChange}
                    onChange={handleRightMandibleChange}
                    title="Right Mandible"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>
                <div style={{ marginTop: 32, marginBottom: 32 }}>
                  <HardwareList
                    classes={classes}
                    hardwareList={genioplastyList}
                    products={products}
                    onAdd={handleAddGenioplasty}
                    onRemove={handleRemoveGenioplasty}
                    onAmountChange={handleAmountGenioplastyChange}
                    onCategoryChange={handleCategoryGenioplastyChange}
                    onChange={handleGenioplastyChange}
                    title="Genioplasty"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>

                <div className="hardware-list-container">
                  <div className="hardware-list-header">
                    <div className="secondary font-size-sm">REQUIRED DATE</div>
                  </div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      id="date-picker"
                      value={date}
                      onChange={(date) => setDate(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={3} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button
          text="Submit"
          color="primary"
          onClick={handleSubmit}
          disabled={!activeCase.access || (!preopPlan && !preop.length) || !maxillaList.length}
        />
      </div>
    </div>
  );
};
