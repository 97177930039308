import { initializeApp } from 'firebase/app';
import {
  getAuth,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  setPersistence,
  browserLocalPersistence,
  inMemoryPersistence,
  multiFactor,
  TotpMultiFactorGenerator,
  getMultiFactorResolver,
  updateProfile,
  updateEmail
} from 'firebase/auth';
import { doc, setDoc, getDoc, initializeFirestore, updateDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

import { firebaseConfig } from '../config';

import { USER_DATA_COLLECTION, USER_AUTHORIZATION_COLLECTION } from './collections';

import { newUserNotificationEmail } from '../util';
import { post } from '../util/api';

import urls from '../constants/urls';
import { multiFactorAuthTypes } from '../constants/enums';

class Firebase {
  constructor() {
    const app = initializeApp(firebaseConfig);

    this.auth = getAuth(app);
    this.db = initializeFirestore(app, { experimentalForceLongPolling: true });
    this.storage = getStorage(app);
    this.messaging = null;
    this.recaptchaVerifier = null;

    // Initialize messaging if supported
    isSupported().then((supported) => {
      if (supported) {
        this.messaging = getMessaging(app);
      }
    }).catch((error) => {
      console.error('Error checking messaging support:', error);
    });
  }

  doCreateUserWithEmailAndPassword = async (email, password, userData) => {
    try {
      const { user } = await createUserWithEmailAndPassword(this.auth, email, password);
      await this.doSendEmailVerification({ ...userData, email });
      await this.saveUserData(user, { ...userData, email });
      await this.setUserRole(null);
      newUserNotificationEmail(userData.firstName, userData.lastName, email);
      return user ? this.doGetCurrentUser() : null;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  };

  doSignInWithEmailAndPassword = async (email, password, checked) => {
    try {
      // Set the persistence type based on the 'checked' state
      await setPersistence(this.auth, checked ? browserLocalPersistence : inMemoryPersistence);

      // Attempt to sign in with email and password
      const authResult = await signInWithEmailAndPassword(this.auth, email, password);

      // Check if the user has multi-factor authentication enabled
      if (authResult.user && authResult?.user?.multiFactor?.enrolledFactors?.length > 0) {
        // If MFA is enabled, return the authenticated user
        return authResult.user;
      } else {
        // If MFA is not enabled, retrieve and return the current user
        return this.doGetCurrentUser();
      }
    } catch (error) {
      // Log the error and rethrow it for the calling code to handle
      console.error('Error signing in:', error);
      throw error;
    }
  };

  doSignOut = async () => await signOut(this.auth);

  doSendEmailVerification = async (userData) => {
    await post(urls.sendVerification, userData);
  };

  doPasswordReset = async (email) => await sendPasswordResetEmail(this.auth, email);

  doReauthenticate = async (currentPassword) => {
    const user = this.auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    await reauthenticateWithCredential(user, credential);
  };

  doPasswordUpdate = async (password) => await updatePassword(this.auth.currentUser, password);

  saveUserData = async (currentUser, userData) => {
    const userDoc = doc(this.db, USER_DATA_COLLECTION, this.auth.currentUser.uid);
    await setDoc(userDoc, userData);
    await updateProfile(currentUser, {
      displayName: `${userData.firstName} ${userData.lastName}`,
    });
  };

  setUserRole = async (role, uid) => {
    const id = uid || this.auth.currentUser.uid;
    const userRoleDoc = doc(this.db, USER_AUTHORIZATION_COLLECTION, id);
    await setDoc(userRoleDoc, { role });
  };

  doGetCurrentUser = () => {
    return new Promise((resolve, reject) => {
      const unsubscribe = this.auth.onAuthStateChanged(
        async (user) => {
          if (user) {
            try {
              const userData = await this.getCurrentUserData();
              const userRoles = await this.getCurrentUserRoles();
              resolve({ ...user, ...userData, ...userRoles });
            } catch (error) {
              reject(error);
            }
          } else {
            resolve(null);
          }
          unsubscribe();
        },
        (error) => reject(error),
      );
    });
  };

  getCurrentUserData = async () => {
    const userDoc = doc(this.db, USER_DATA_COLLECTION, this.auth.currentUser.uid);
    const docSnap = await getDoc(userDoc);
    return docSnap.exists() ? docSnap.data() : null;
  };

  getCurrentUserRoles = async () => {
    const rolesDoc = doc(this.db, USER_AUTHORIZATION_COLLECTION, this.auth.currentUser.uid);
    const docSnap = await getDoc(rolesDoc);
    return docSnap.exists() ? docSnap.data() : null;
  };

  getUserData = async (uid) => {
    const userDoc = doc(this.db, USER_DATA_COLLECTION, uid);
    const docSnap = await getDoc(userDoc);
    return docSnap.exists() ? docSnap.data() : null;
  };

  getDownloadLink = async (fileId) => {
    try {
      const fileRef = ref(this.storage, fileId);
      return await getDownloadURL(fileRef);
    } catch (error) {
      console.error('Error getting download link:', error);
      throw error;
    }
  };

  getNotificationToken = async () => {
    if (this.messaging) {
      return await getToken(this.messaging, { vapidKey: firebaseConfig.vapidKey });
    }
    return null;
  };

  onMessageListener = () =>
    new Promise((resolve) => {
      if (this.messaging) {
        onMessage(this.messaging, (payload) => {
          resolve(payload);
        });
      } else {
        resolve();
      }
    });

  updateUserEmail = async (newEmail, password, firstName, lastName) => {
    const user = this.auth.currentUser;

    try {
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      await updateEmail(user, newEmail);

      const userDoc = doc(this.db, USER_DATA_COLLECTION, user.uid);
      await setDoc(userDoc, { email: newEmail });

      await post(urls.sendVerification, { ...user, email: newEmail, firstName, lastName });

      console.log('Email changed successfully');
    } catch (error) {
      console.error('Error changing email', error);
      throw error;
    }
  };

  enable2FA = async (phoneNumber, password) => {
    const user = this.auth.currentUser;

    if (!multiFactor(user)) {
      console.error('Multi-factor authentication is not supported for this user.');
      throw new Error('Multi-factor authentication is not supported for this user.');
    }

    try {
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      const recaptchaVerifier = this.getRecaptchaVerifier('recaptcha-container');
      const multiFactorSession = await multiFactor(user).getSession();

      const phoneAuthProvider = new PhoneAuthProvider(this.auth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber({
        phoneNumber,
        session: multiFactorSession,
      }, recaptchaVerifier);

      const verificationCode = window.prompt('Please enter the verification code sent to your mobile device.');
      const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);

      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneCredential);
      await multiFactor(user).enroll(multiFactorAssertion, 'My Phone Number');

      await setDoc(doc(this.db, USER_AUTHORIZATION_COLLECTION, user.uid), {
        multiFactorEnabled: true,
        multiFactorType: multiFactorAuthTypes.SMS,
      }, { merge: true });

      console.log('2FA enabled successfully');
    } catch (error) {
      console.error('Error enabling 2FA:', error);
      throw error;
    }
  };

  disable2FA = async (password) => {
    const user = this.auth.currentUser;

    if (!multiFactor(user)) {
      console.error('Multi-factor authentication is not supported for this user.');
      return;
    }

    try {
      // Re-authenticate the user with password
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential).catch(async (error) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          const resolver = getMultiFactorResolver(this.auth, error);
          const enrolledFactors = resolver.hints;

          // Handle all enrolled factors
          for (const factor of enrolledFactors) {
            if (factor.factorId === 'phone') {
              const recaptchaVerifier = this.getRecaptchaVerifier('recaptcha-container');
              const phoneAuthProvider = new PhoneAuthProvider(this.auth);
              const verificationId = await phoneAuthProvider.verifyPhoneNumber(
                {
                  multiFactorHint: factor,
                  session: resolver.session,
                },
                recaptchaVerifier
              );
              const verificationCode = window.prompt('Please enter the verification code sent to your mobile device.');
              const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
              const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneCredential);
              await resolver.resolveSignIn(multiFactorAssertion);
            } else if (factor.factorId === 'totp') {
              const otp = window.prompt('Please enter the OTP from your authenticator app.');
              const totpAssertion = await TotpMultiFactorGenerator.assertionForSignIn(factor.uid, otp);
              await resolver.resolveSignIn(totpAssertion);
            }
          }
        }
      });

      await user.reload();
      const mfaInfo = multiFactor(user);
      const enrolledFactors = mfaInfo.enrolledFactors;

      // Unenroll all factors
      for (const factor of enrolledFactors) {
        await multiFactor(user).unenroll(factor.uid);
      }

      // Update Firestore if necessary
      await setDoc(doc(this.db, USER_AUTHORIZATION_COLLECTION, user.uid), {
        multiFactorEnabled: false,
        multiFactorType: null,
      }, { merge: true });

      console.log('2FA disabled successfully.');
    } catch (error) {
      console.error('Error disabling 2FA:', error);
      throw error;
    }
  };

  generateTOTPSecret = async (password) => {
    const user = this.auth.currentUser;

    if (!multiFactor(user)) {
      console.error('Multi-factor authentication is not supported for this user.');
      return;
    }

    try {
      // Reauthenticate the user
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      // Get the multi-factor session for TOTP enrollment
      const multiFactorSession = await multiFactor(user).getSession();

      // Generate a TOTP secret
      const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
      return totpSecret;
    } catch (err) {
      console.error(err);
    }
  };

  verifyTOTP = async (otp, secret, userName) => {
    const user = this.auth.currentUser;
    try {
      const totpAssertion = await TotpMultiFactorGenerator.assertionForEnrollment(secret, otp);
      await multiFactor(user).enroll(totpAssertion, `${userName} Authenticator`);
      await setDoc(
        doc(this.db, USER_AUTHORIZATION_COLLECTION, user.uid),
        { multiFactorEnabled: true, multiFactorType: multiFactorAuthTypes.TOTP },
        { merge: true }
      );
    } catch (err) {
      console.error('Error verifying OTP:', err);
      throw new Error('Invalid OTP');
    }
  };

  getRecaptchaVerifier(containerId) {
    // Ensure that Firebase Auth is correctly initialized
    if (!this.auth) {
      console.error('Firebase Auth is not initialized.');
      return null;
    }

    // Ensure the container element exists in the DOM
    const containerElement = document.getElementById(containerId);
    if (!containerElement) {
      console.error(`Container with ID "${containerId}" not found.`);
      return null;
    }

    // Check if reCAPTCHA verifier already exists, if not, create it
    if (!this.recaptchaVerifier) {
      try {
        // Create a new RecaptchaVerifier instance with correct parameter order
        this.recaptchaVerifier = new RecaptchaVerifier(
          this.auth, // Pass the Firebase Auth instance as the first parameter
          containerId,
          {
            size: 'invisible', // Options: 'invisible', 'normal', 'compact'
            callback: (response) => {
              console.log('reCAPTCHA verified:', response);
            },
            'expired-callback': () => {
              console.warn('reCAPTCHA expired, please verify again.');
            }
          }
        );

        // Render the reCAPTCHA and handle errors
        this.recaptchaVerifier.render().then((widgetId) => {
          console.log(`reCAPTCHA rendered with widget ID: ${widgetId}`);
        }).catch((renderError) => {
          console.error('Error rendering reCAPTCHA:', renderError);
        });
      } catch (error) {
        console.error('Error initializing reCAPTCHA:', error);
      }
    }

    return this.recaptchaVerifier;
  }
}

const instance = new Firebase();

export default instance;
