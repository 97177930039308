// Sidebar actions
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const TRAINING_MODE_ON = 'TRAINING_MODE_ON';
export const TRAINING_MODE_OFF = 'TRAINING_MODE_OFF';

// Current user actions
export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

// Users actions
export const LOAD_USERS = 'LOAD_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const SET_ROLE = 'SET_ROLE';
export const DISABLE_USER = 'DISABLE_USER';
export const DISABLE_2FA = 'DISABLE_2FA';
export const CHANGE_ENGINEER_LEVEL = 'CHANGE_ENGINEER_LEVEL';

// Countries
export const LOAD_COUNTRY_LIST = 'LOAD_COUNTRY_LIST';
export const RECEIVE_COUNTRY_LIST = 'RECEIVE_COUNTRY_LIST';

// Procedures
export const LOAD_PROCEDURES = 'LOAD_PROCEDURES';
export const SET_PROCEDURES = 'SET_PROCEDURES';
export const ADD_PROCEDURE = 'ADD_PROCEDURE';
export const DELETE_PROCEDURE = 'DELETE_PROCEDURE';
export const TOGGLE_PROCEDURE = 'TOGGLE_PROCEDURE';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
export const UPDATE_FLOW = 'UPDATE_FLOW';
export const UPDATE_LEAD_DAYS = 'UPDATE_LEAD_DAYS';

// Distributors
export const LOAD_DISTRIBUTORS = 'LOAD_DISTRIBUTORS';
export const SET_DISTRIBUTORS = 'SET_DISTRIBUTORS';
export const ADD_DISTRIBUTOR = 'ADD_DISTRIBUTOR';
export const UPDATE_DISTRIBUTOR = 'UPDATE_DISTRIBUTOR';
export const DELETE_DISTRIBUTOR = 'DELETE_DISTRIBUTOR';
export const UPDATE_DISTRIBUTOR_PROCEDURE = 'UPDATE_DISTRIBUTOR_PROCEDURE';

// Hospitals
export const LOAD_HOSPITALS = 'LOAD_HOSPITALS';
export const SET_HOSPITALS = 'SET_HOSPITALS';
export const ADD_HOSPITAL = 'ADD_HOSPITAL';
export const DELETE_HOSPITAL = 'DELETE_HOSPITAL';

// Manufacturers
export const LOAD_MANUFACTURERS = 'LOAD_MANUFACTURERS';
export const SET_MANUFACTURERS = 'SET_MANUFACTURERS';
export const ADD_MANUFACTURER = 'ADD_MANUFACTURER';
export const DELETE_MANUFACTURER = 'DELETE_MANUFACTURER';

// Manufacturers
export const LOAD_RADIOLOGY = 'LOAD_RADIOLOGY';
export const SET_RADIOLOGY = 'SET_RADIOLOGY';
export const ADD_RADIOLOGY = 'ADD_RADIOLOGY';
export const DELETE_RADIOLOGY = 'DELETE_RADIOLOGY';

// Surgeons
export const LOAD_SURGEONS = 'LOAD_SURGEONS';
export const SET_SURGEONS = 'SET_SURGEONS';
export const ADD_SURGEON = 'ADD_SURGEON';
export const DISABLE_SURGEON = 'DISABLE_SURGEON';
export const ENABLE_SURGEON = 'ENABLE_SURGEON';
export const UPDATE_SURGEON = 'UPDATE_SURGEON';
export const UPDATE_SURGEON_HOSPITALS = 'UPDATE_SURGEON_HOSPITALS';

// Cases
export const LOAD_CASES = 'LOAD_CASES';
export const SET_CASES = 'SET_CASES';
export const LOAD_COMPLETED_CASES = 'LOAD_COMPLETED_CASES';
export const SET_COMPLETED_CASES = 'SET_COMPLETED_CASES';
export const LOAD_ARCHIVED_CASES = 'LOAD_ARCHIVED_CASES';
export const SET_ARCHIVED_CASES = 'SET_ARCHIVED_CASES';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const CREATE_CASE = 'CREATE_CASE';
export const UPDATE_CASE = 'UPDATE_CASE';
export const SET_ACTIVE_CASE = 'SET_ACTIVE_CASE';
export const DISCARD_ACTIVE_CASE = 'DISCARD_ACTIVE_CASE';
export const RESET_CASES = 'RESET_CASES';
export const SET_SELECTED_FLOW = 'SET_SELECTED_FLOW';
export const SET_CASE_NOTES = 'SET_CASE_NOTES';
export const ADD_CASE_NOTE = 'ADD_CASE_NOTE';

// Notification
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';

// Activity
export const LOAD_ACTIVITY_LIST = 'LOAD_ACTIVITY_LIST';
export const SET_ACTIVITY_LIST = 'SET_ACTIVITY_LIST';
export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const RESET_ACTIVITY = 'RESET_ACTIVITY';

// Documents
export const LOAD_DOCUMENTS = 'LOAD_DOCUMENTS';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';

// Products
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const DELETE_PRODUCTS = 'DELETE_PRODUCTS';

// Settings
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';