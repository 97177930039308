import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Hidden from '@material-ui/core/Hidden';

import Modal from '../../modals/Modal';
import Button from '../../shared/Button';

const CaseRequestDateModal = (props) => {
  const { open, onClose, selectedCase, onSubmit, loading,} = props;

  const [date, setDate] = useState(moment());

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Request New Surgery Date"
      subtitle={selectedCase?.formattedId || selectedCase?.id}
      onSubmit={() => onSubmit(date)}
      size="md"
      autoSize
      disabled={loading}
      loading={loading}
    >
      <div className="d-flex direction-column space-between">
        <div className="p-lg width-100">
          <div className="secondary">Surgery Date</div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              fullWidth
              id="date-picker-disabled"
              value={selectedCase?.date}
              disabled
            />
          </MuiPickersUtilsProvider>
        </div>

        <Hidden smDown implementation="css">
          <div className="p-lg font-bold" style={{ maxWidth: 400 }}>
            Please request a new surgery date below that will be reviewed & approved by any admin
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div className='p-lg'>
            Please request a new surgery date below that will be reviewed & approved by any admin
          </div>
        </Hidden>

        <div className="p-lg width-100">
          <div className="secondary">Requested Surgery Date</div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              fullWidth
              id="date-picker"
              value={date}
              onChange={(date) => setDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDate={moment()}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>

      <div className="d-flex w-100 space-between p-lg m-t-lg">
        <div className="secondary pointer" onClick={onClose}>
          Cancel
        </div>
        <Button text="Request Date" color="primary" onClick={() => onSubmit(date)} />
      </div>
    </Modal>
  );
};

export default CaseRequestDateModal;
