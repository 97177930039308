import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import calendarIcon from '../../../../../../assets/calendar_icon.svg';
import consultationFileIcon from '../../../../../../assets/preop_icon.svg';

import { fileTypes } from '../../../../../../constants/cases';

import '../../../../../modals/case/case-modal.scss';

const ConsultationFileReview = ({ classes, activeCase }) => {
  const {
    patientInfo,
    date,
    hospital,
    procedureName,
  } = activeCase;

  const consultationFile = useMemo(() => activeCase?.files?.find((f) => f.type === fileTypes.alignmentAnalysis), [activeCase.files]);

  return (
    <div className="modal-item-block flex-1 height-100 margin-0">
      {!!consultationFile && (
        <div className="modal-item-block margin-0">
          <div className="secondary font-size-sm border-bottom p-b-md m-b-md">
            Please review the preoperative alignment analysis document via the link below
          </div>
          <div className="d-flex space-between">
            <div>
              {patientInfo.firstName} {patientInfo.lastName}
            </div>
            <div className="d-flex">
              <img src={calendarIcon} alt="calendar-icon" height={15} className="m-r-sm" />
              {moment(date).format('DD/MM/YYYY')}
            </div>
          </div>
          <div className="d-flex space-between m-b-lg">
            <div>{hospital}</div>
            <div className={classes.primary}>{procedureName}</div>
          </div>
          <div className="block-header">Preoperative Alignment Analysis</div>
          <div className="preop-image-container d-flex flex-center">
            <a
              href={consultationFile.downloadLink}
              target="_blank"
              className={`${classes.primary} text-center`}
              rel="noopener noreferrer"
              download
            >
              <img src="Pre-Op_Alignment_Analysis_Front_Page_drop_Osteotomy.png" alt="consultationFile" style={{ maxHeight: 300 }} />
              <div className=" text-center pointer">Click to view</div>
            </a>
          </div>
          <div className="d-flex p-t-md p-b-md flex-center">
            <img src={consultationFileIcon} alt="consultationFile-icon" height={20} className="m-r-sm" />
            <span>{consultationFile.name}</span>
          </div>
        </div>
      )}
    </div>
  );
};

ConsultationFileReview.propTypes = {
  activeCase: PropTypes.object,
};

export default ConsultationFileReview;
