import React, { useState } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../../shared/Button';

import { updateCase } from '../../../../../../actions/casesActions';

import { caseConstants, userRoles } from '../../../../../../constants';

const OsteotomyStep3Static = ({ activeCase, updateCase, onClose = () => {}, userRole }) => {
  const { alignmentData } = activeCase;
  const { calculationDataHTO, calculationDataDFO, procedureFlows } = caseConstants;

  const [loading, setLoading] = useState(false);

  const getCalculationData = (flow) => {
    switch (flow) {
      case procedureFlows.HTO:
        return [
          'JLCA',
          'WBL',
          ...calculationDataHTO.tibia.map((item) => item.field),
          ...calculationDataHTO.femur.map((item) => item.field),
          ...calculationDataHTO.coronal.map((item) => item.field),
          ...calculationDataHTO.sagittal.map((item) => item.field),
        ];
      case procedureFlows.DFO_Lateral:
        return [
          'JLCA',
          'WBL',
          ...calculationDataDFO.tibia.map((item) => item.field),
          ...calculationDataDFO.femur.map((item) => item.field),
          ...calculationDataDFO.coronal.map((item) => item.field),
          ...calculationDataDFO.sagittal.map((item) => item.field),
        ];
      case procedureFlows.DFO_Medial:
        return [
          'JLCA',
          'WBL',
          ...calculationDataDFO.tibia.map((item) => item.field),
          ...calculationDataDFO.femur.map((item) => item.field),
          ...calculationDataDFO.coronal.map((item) => item.field),
          ...calculationDataDFO.sagittal.map((item) => item.field),
        ];
      default:
        return [
          'JLCA',
          'WBL',
          ...calculationDataHTO.tibia.map((item) => item.field),
          ...calculationDataHTO.femur.map((item) => item.field),
          ...calculationDataHTO.coronal.map((item) => item.field),
          ...calculationDataHTO.sagittal.map((item) => item.field),
        ];
    }
  };

  const onRejectClick = async () => {
    setLoading(true);

    try {
      await updateCase({ ...activeCase, step: 3 }, 4, [], true);
      onClose();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="case-static-container">
      <Grid container spacing={1}>
        {getCalculationData(activeCase.procedureFlow).map((key) => (
          <Grid key={key} item xs={6} sm={4} md={3}>
            <div className="d-flex">
              <div className="secondary">{key}:</div>
              <div className="m-l-sm">{alignmentData?.[key]}</div>
            </div>
          </Grid>
        ))}
        <Grid item xs={6} sm={4} md={3}>
          <div className="d-flex">
            <div className="secondary">Pre-Op HKA</div>
            <div className="m-l-sm">{alignmentData?.["Pre-Op_HKA"]}</div>
          </div>
        </Grid>
      </Grid>
      {activeCase.step === 4 && (userRole === userRoles.GLOBAL_ADMIN.name || userRole === userRoles.COUNTRY_ADMIN.name) && (
        <div className="m-t-lg">
          <Button
            text="Back to step 3"
            color="primary"
            onClick={onRejectClick}
            disabled={loading}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    userRole: state.user.currentUser.role
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (activeCase, step, files, rejected) => dispatch(updateCase(activeCase, step, files, rejected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OsteotomyStep3Static);
