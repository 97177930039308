import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';

import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

import Table from '../shared/table';

import {
  updateCaseDoc,
  sendChangeDateHospitalEmail,
  requestCaseDateUpdate,
  approveRequestDateUpdate,
  rejectRequestDateUpdate,
} from '../../actions/casesActions';

// import scansIcon from '../../assets/scans_icon.svg';
// import preopIcon from '../../assets/preop_icon.svg';
// import blockIcon from '../../assets/block_icon.svg';
import filterIcon from '../../assets/filter_icon.svg';
import calendarIcon from '../../assets/calendar_icon_primary.svg';

import userRoles from '../../constants/userRoles';
import { defaultLeadDays } from '../../constants/cases';

import './cases.scss';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(1),
    fontSize: '12px',
    whiteSpace: 'pre-line !important',
  },
}));

const CasesTable = ({
  cases = [],
  distributors = [],
  onClick,
  goToFiles,
  filter,
  filterOptions,
  onFilterChange,
  onRemoveFilterItem,
  radiology = false,
  role,
  procedures,
  onlyRequired,
  onAdminApproveDateClick,
  onTBCClick,
  onRequestDateClick,
  onAdminApproveConsultationDateClick,
  onRequestConsultationDateClick,
}) => {
  const classes = useStyles();

  const renderDateCell = (value, row) => {
    const isAdmin = [userRoles.GLOBAL_ADMIN.name, userRoles.COUNTRY_ADMIN.name, userRoles.DISTRIBUTOR_ADMIN.name]?.includes(role);

    if (row.requestedSurgeryDate) {
      return (
        <Tooltip
          title={`New Case Date Requested – ${moment(row?.requestedSurgeryDate?.newDate).format('DD/MM/YY')}`}
          placement="bottom"
          enterTouchDelay={50}
          classes={{ tooltip: classes.tooltip }}
          leaveTouchDelay={3000}
        >
          <div
            className="pointer p-sm d-flex"
            onClick={(e) => {
              if (isAdmin) {
                e.stopPropagation();
                onAdminApproveDateClick(row);
              }
            }}
          >
          <span>
            {value ? moment(value).format('DD/MM/YYYY') : 'TBC'}
          </span>
            <img style={{ fill: '#000000 !important' }} src={calendarIcon} alt="calendar-icon" height={15}
                 className="m-l-md" />
          </div>
        </Tooltip>
      );
    }

    if (!value || !!row.postponed) {
      return (
        <div
          className="primary pointer p-sm"
          onClick={(e) => {
            e.stopPropagation();
            onTBCClick(row);
          }}
        >
          TBC
        </div>
      );
    }

    return (
      isAdmin ? (
        moment(value).format('DD/MM/YYYY')
      ) : (
        <Tooltip
          title='Request New Surgery Date'
          placement="bottom"
          enterTouchDelay={50}
          classes={{ tooltip: classes.tooltip }}
          leaveTouchDelay={3000}
        >
          <div
            className="primary pointer p-sm"
            onClick={(e) => {
              e.stopPropagation();
              onRequestDateClick(row);
            }}
          >
            {moment(value).format('DD/MM/YYYY')}
          </div>
        </Tooltip>
      )
    );
  }

  const renderConsultationDateCell = (value, row) => {
    const isAdmin = [userRoles.GLOBAL_ADMIN.name, userRoles.COUNTRY_ADMIN.name, userRoles.DISTRIBUTOR_ADMIN.name]?.includes(role);

    if (row.requestedConsultationDate) {
      return (
        <Tooltip
          title={`New Consultation Date Requested – ${moment(row?.requestedConsultationDate?.newDate).format('DD/MM/YY')}`}
          placement="bottom"
          enterTouchDelay={50}
          classes={{ tooltip: classes.tooltip }}
          leaveTouchDelay={3000}
        >
          <div
            className="pointer p-sm d-flex"
            onClick={(e) => {
              if (isAdmin) {
                e.stopPropagation();
                onAdminApproveConsultationDateClick(row);
              }
            }}
          >
          <span>
            {value ? moment(value).format('DD/MM/YYYY') : 'TBC'}
          </span>
            <img style={{ fill: '#000000 !important' }} src={calendarIcon} alt="calendar-icon" height={15}
                 className="m-l-md" />
          </div>
        </Tooltip>
      );
    }

    if (!value) {
      return '';
    }

    return (
      isAdmin ? (
        moment(value).format('DD/MM/YYYY')
      ) : (
        <Tooltip
          title='Request New Consultation Date'
          placement="bottom"
          enterTouchDelay={50}
          classes={{ tooltip: classes.tooltip }}
          leaveTouchDelay={3000}
        >
          <div
            className="primary pointer p-sm"
            onClick={(e) => {
              e.stopPropagation();
              onRequestConsultationDateClick(row);
            }}
          >
            {moment(value).format('DD/MM/YYYY')}
          </div>
        </Tooltip>
      )
    );
  }

  const columns = useMemo(() => {
    if (radiology) {
      return [
        { title: '', field: 'access', type: 'indicator', filter: true },
        { title: 'case id', field: 'formattedId', type: 'default' },
        { title: 'patient', field: 'patientLastName', type: 'default', filter: true },
        {
          title: 'consultation',
          field: 'consultationDate',
          type: 'custom',
          formatter: renderConsultationDateCell,
        },
        {
          title: 'surgery',
          field: 'date',
          type: 'custom',
          formatter: renderDateCell,
        },
        { title: 'surgeon', field: 'surgeonName', type: 'default', filter: true },
        { title: 'procedure', field: 'procedureName', type: 'default', filter: true },
        { title: 'action', field: 'action', type: 'caseAction', filter: true },
        { title: 'step', field: 'step', type: 'step', filter: true },
        // { title: 'scans', field: 'scan', type: 'file', icon: scansIcon, onClick: goToFiles },
      ];
    }
    return [
      { title: '', field: 'access', type: 'indicator', filter: true },
      { title: 'case id', field: 'formattedId', type: 'default' },
      { title: 'patient', field: 'patientLastName', type: 'default', filter: true },
      {
        title: 'consultation',
        field: 'consultationDate',
        type: 'custom',
        formatter: renderConsultationDateCell,
      },
      {
        title: 'surgery',
        field: 'date',
        type: 'custom',
        formatter: renderDateCell,
        filter: true,
      },
      { title: 'surgeon', field: 'surgeonName', type: 'default', filter: true },
      { title: 'hospital', field: 'hospital', type: 'default', filter: true },
      { title: 'country', field: 'country', type: 'default', filter: true },
      { title: 'procedure', field: 'procedureName', type: 'default', filter: true },
      { title: 'action', field: 'action', type: 'caseAction', filter: true },
      { title: 'step', field: 'step', type: 'step', filter: true },
      // { title: 'scans', field: 'scan', type: 'file', icon: scansIcon, onClick: goToFiles },
      // { title: 'preop', field: 'preop', type: 'file', icon: preopIcon, onClick: goToFiles },
      // { title: 'block', field: 'block', type: 'file', icon: blockIcon, onClick: goToFiles }
    ];
  }, [cases, radiology]);

  const handleClick = (row) => {
    onClick(row);
  };

  const getCountryName = (distributorId) => {
    const distributor = distributors?.find((d) => d.id === distributorId);
    return distributor?.country || '';
  };

  const getFormattedId = (item) => {
    if (!item || !item.id) {
      return '';
    }
    if (item?.formattedId) {
      return item.formattedId;
    }
    const arr = item?.id?.split('_');
    return arr?.length > 1 ? arr[0] : item?.id;
  };

  const getSortDate = (item) => {
    if (item.date) {
      return item.date;
    }

    const procedure = procedures?.find((p) => p.id === item.procedure);
    const leadDays = procedure?.leadDays || defaultLeadDays;

    return moment(item.createdAt)?.add(leadDays, 'days')?.format() || null;
  };

  const rows = sortBy(cases?.map((item) => ({
    ...item,
    sortDate: getSortDate(item),
    formattedId: getFormattedId(item),
    country: getCountryName(item.distributorId),
  })), 'sortDate');

  return (
    <Fragment>
      {filter &&
        (Object.keys(filter)?.filter((key) => key !== 'access')?.some((key) => Array.isArray(filter[key]) ? filter[key]?.length : !!filter[key]) || filter?.access?.length > 1)
        && (
        <div className="filter-list">
          <img src={filterIcon} alt="filter-icon" />
          {Object.keys(filter).map((key) => (
            key === 'date' && filter.date ? (
              <div key={key} className="filter-list-item-container">
                <div className="filter-list-item">
                  <div />
                  <div className="filter-list-item-value">{`${moment(filter?.date?.from).format('DD/MM/YY')} – ${moment(filter?.date?.to).format('DD/MM/YY')}`}</div>
                  <CloseIcon color="secondary" onClick={() => onRemoveFilterItem('date')} />
                </div>
              </div>
            ) : (
              <div key={key} className="filter-list-item-container">
                {filter[key]?.filter((value) => value !== 'Open').map((value) => (
                  <div key={`${key}-${value}`} className="filter-list-item">
                    <div />
                    <div className="filter-list-item-value">{value}</div>
                    <CloseIcon color="secondary" onClick={() => onRemoveFilterItem(key, value)} />
                  </div>
                ))}
              </div>
            )
          ))}
        </div>
      )}
      <Table
        stickyTable
        rows={onlyRequired ? rows.slice(0,5) : rows}
        columns={columns}
        onRowClick={handleClick}
        onFilterChange={onFilterChange}
        currentFilter={filter}
        filterOptions={filterOptions}
        withoutPagination={onlyRequired}
      />
    </Fragment>
  );
};

CasesTable.propTypes = {
  cases: PropTypes.array,
  filter: PropTypes.object,
  filterOptions: PropTypes.object,
  onFilterChange: PropTypes.func,
  onRemoveFilterItem: PropTypes.func,
  onClick: PropTypes.func,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    hospitals: state?.hospitals?.list || [],
    surgeons: state?.surgeons?.list || [],
    procedures: state?.procedures?.list || [],
    role: state.user.currentUser.role,
    engineerLevel: state.user.currentUser.engineerLevel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCaseDoc: (caseId, data) => dispatch(updateCaseDoc(caseId, data)),
    sendChangeDateHospitalEmail: (caseId, data, previousData) => dispatch(sendChangeDateHospitalEmail(caseId, data, previousData)),
    requestCaseDateUpdate: (activeCase, date, hospital) => dispatch(requestCaseDateUpdate(activeCase, date, hospital)),
    approveRequestDateUpdate: (activeCase) => dispatch(approveRequestDateUpdate(activeCase)),
    rejectRequestDateUpdate: (activeCase) => dispatch(rejectRequestDateUpdate(activeCase))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CasesTable);
