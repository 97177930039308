import React from 'react';

import { Grid, RadioGroup, Radio, Checkbox, FormControlLabel } from '@material-ui/core';

import CalculationInput from '../CalculationInput';
import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';

import Button from '../../../../../shared/Button';

import { procedureFlows } from '../../../../../../constants/cases';
import { utils } from '../../../../../../util';
import Dropzone from '../../../../../shared/dropzone/Dropzone';

export const DfoForm = ({
  trainingMode,
  loading,
  handleSetTrainingData,
  type,
  activeCase,
  setType,
  data,
  handleHKAChange,
  validationMode,
  validation,
  onCheck,
  handleChange,
  calculationData,
  calculating,
  HKA,
  DCA,
  RCA,
  PTS,
  initialTibialWidth,
  tibialWidth = 0,
  initialPTS,
  onClose,
  handleSubmit,
  isDisabled,
  toggleModal,
  planFile,
  setPlanFile,
  handleRemovePlanFile
}) => {
  return (
    <>
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">3</div>
          </div>
          <div className="modal-item-content">
            <div className="modal-item-header">
              <div>Load Alignment Data</div>
              {trainingMode && (
                <Button
                  text="Load Dataset"
                  color="primary"
                  onClick={() => handleSetTrainingData(type)}
                  disabled={loading}
                  loading={loading}
                />
              )}
            </div>

            {activeCase.access ? (
              <>
                <div className="modal-item-block">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="radio-group-container">
                        <RadioGroup
                          aria-label="calculationType"
                          name="calculationType"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <FormControlLabel
                            value="Varus"
                            control={<Radio color="primary" />}
                            label="Varus"
                            disabled={activeCase.procedureFlow === procedureFlows.DFO_Medial}
                          />
                          <FormControlLabel
                            value="Valgus"
                            control={<Radio color="primary" />}
                            label="Valgus"
                          />
                        </RadioGroup>
                      </div>
                    </Grid>
                    {!trainingMode && (
                      <>
                        <Grid item sm={6} xs={12}>
                          <div className="d-flex">
                            <CalculationInput
                              value={data['JLCA']}
                              onChange={(value) => handleChange('JLCA', value)}
                              label="JLCA"
                              unit="degrees"
                              max={3}
                            />
                            {validationMode && (
                              <Checkbox
                                color="primary"
                                checked={validation['JLCA']}
                                onChange={(e) => onCheck(e, 'JLCA')}
                                disabled={!data['JLCA']}
                              />
                            )}
                          </div>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <div className="d-flex">
                            <CalculationInput
                              value={data['HKA']}
                              onChange={handleHKAChange}
                              label="HKA"
                              unit="degrees"
                              max={180}
                            />
                            {validationMode && (
                              <Checkbox
                                color="primary"
                                checked={validation['HKA']}
                                onChange={(e) => onCheck(e, 'HKA')}
                                disabled={!data['HKA']}
                              />
                            )}
                          </div>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <div className="d-flex">
                            <CalculationInput
                              value={data['WBL']}
                              onChange={(value) => handleChange('WBL', value)}
                              label="WBL"
                              unit="mm"
                            />
                            {validationMode && (
                              <Checkbox
                                color="primary"
                                checked={validation['WBL']}
                                onChange={(e) => onCheck(e, 'WBL')}
                                disabled={!data['WBL']}
                              />
                            )}
                          </div>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <div className="d-flex">
                            <CalculationInput
                              label="Pre-Op_HKA"
                              value={data['Pre-Op_HKA']}
                              disabled
                            />
                          </div>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </div>
                {!trainingMode && (
                  <div className="modal-item-block">
                    <div className="block-header">Tibia</div>
                    <Grid container spacing={2}>
                      {calculationData.tibia.map((item) => (
                        <Grid key={item.field} item sm={6} xs={12}>
                          <div className="d-flex">
                            <CalculationInput
                              value={data[item.field]}
                              onChange={(value) => handleChange(item.field, value)}
                              label={item.field}
                              unit={item.unity}
                              disabled={item.disabled || loading}
                              calculating={item.disabled && calculating}
                            />
                            {validationMode && !item.disabled && (
                              <Checkbox
                                color="primary"
                                checked={validation[item.field]}
                                onChange={(e) => onCheck(e, item.field)}
                                disabled={!data[item.field]}
                              />
                            )}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}

                {!trainingMode && (
                  <div className="modal-item-block">
                    <div className="block-header">Femur</div>
                    <Grid container spacing={2}>
                      {calculationData.femur.map((item) => (
                        <Grid key={item.field} item sm={6} xs={12}>
                          <div className="d-flex">
                            <CalculationInput
                              value={data[item.field]}
                              onChange={(value) => handleChange(item.field, value)}
                              label={item.field}
                              unit={item.unity}
                              disabled={item.disabled || loading}
                              calculating={item.disabled && calculating}
                            />
                            {validationMode && !item.disabled && (
                              <Checkbox
                                color="primary"
                                checked={validation[item.field]}
                                onChange={(e) => onCheck(e, item.field)}
                                disabled={!data[item.field]}
                              />
                            )}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}

                {!trainingMode && (
                  <div className="modal-item-block">
                    <div className="block-header">Coronal</div>
                    <Grid container spacing={2}>
                      {calculationData.coronal.map((item) => (
                        <Grid key={item.field} item sm={6} xs={12}>
                          <div className="d-flex">
                            <CalculationInput
                              value={data[item.field]}
                              onChange={(value) => handleChange(item.field, value)}
                              label={item.field}
                              unit={item.unity}
                              min={item.min}
                              max={item.max}
                            />
                            {validationMode && (
                              <Checkbox
                                color="primary"
                                checked={validation[item.field]}
                                onChange={(e) => onCheck(e, item.field)}
                                disabled={!data[item.field]}
                              />
                            )}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}

                {!trainingMode && (
                  <div className="modal-item-block">
                    <div className="block-header">Sagittal</div>
                    <Grid container spacing={2}>
                      {calculationData.sagittal.map((item) => (
                        <Grid key={item.field} item sm={6} xs={12}>
                          <div className="d-flex">
                            <CalculationInput
                              value={data[item.field]}
                              onChange={(value) => handleChange(item.field, value)}
                              label={item.field}
                              unit={item.unity}
                              min={item.min}
                              max={item.max}
                            />
                            {validationMode && (
                              <Checkbox
                                color="primary"
                                checked={validation[item.field]}
                                onChange={(e) => onCheck(e, item.field)}
                                disabled={!data[item.field]}
                              />
                            )}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}

                {!trainingMode && (
                  <div className="modal-item-block">
                    <div className="d-flex align-start m-t-lg">
                      <div className="flex-1 m-r-md">
                        <div className="block-header">Preoperative</div>
                        <div className="summary-item">
                          <div>
                            HKA <span className="secondary">(°)</span> '
                            {utils.getPreoperativeHkaLabel(activeCase.procedureFlow, type)}'
                          </div>
                          <div className="font-bold">{HKA}</div>
                        </div>
                        <div className="summary-item">
                          <div>
                            WBL <span className="secondary">(%)</span>
                          </div>
                          <div className="font-bold">{initialTibialWidth}</div>
                        </div>
                      </div>
                      <div className="flex-1 m-l-md">
                        <div className="block-header">Postoperative</div>
                        <div className="summary-item">
                          <div>
                            HKA <span className="secondary">(°)</span> '
                            {utils.getPostoperativeHkaLabel(activeCase.procedureFlow, type)}'
                          </div>
                          <div className="font-bold">{DCA}</div>
                        </div>
                        <div className="summary-item">
                          <div>
                            WBL Intersection <span className="secondary">(%)</span>
                          </div>
                          <div className="font-bold">{Number(tibialWidth)}</div>
                        </div>
                        <div className="summary-item">
                          <div>
                            Correction Angle <span className="secondary">(°)</span>
                          </div>
                          <div className="font-bold">{RCA}</div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-item-block m-b-md">
                      <div className="summary-title">Posterior Distal Femoral Angle</div>
                      <div className="d-flex">
                        <div className="flex-1 m-r-md">
                          <div className="summary-item">
                            <div>
                              PDFA <span className="secondary">(°)</span>
                            </div>
                            <div className="font-bold">{initialPTS}</div>
                          </div>
                        </div>
                        <div className="flex-1 m-l-md">
                          <div className="summary-item">
                            <div>
                              PDFA <span className="secondary">(°)</span>
                            </div>
                            <div className="font-bold">{PTS}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <div className="block-header">Preoperative Alignment Analysis (.pdf)</div>
                  <Dropzone
                    files={planFile}
                    onUpload={setPlanFile}
                    type="pdf"
                    icon="preop"
                    multiple={false}
                    onRemove={handleRemovePlanFile}
                  />
                </div>
              </>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={3} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        {!trainingMode && (
          <>
            {validationMode ? (
              <Button
                text="Continue"
                color="primary"
                onClick={handleSubmit}
                disabled={isDisabled() || !activeCase.access}
              />
            ) : (
              <Button
                text="Validate"
                color="primary"
                onClick={() => toggleModal(true)}
                disabled={isDisabled() || !activeCase.access}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
