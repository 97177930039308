import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import Grid from '@material-ui/core/Grid';
import { Checkbox } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import UserSelect from '../../../../shared/UserSelect';
import Button from '../../../../shared/Button';
import Alert from '../../../../shared/Alert';
import Dropzone from '../../../../shared/dropzone/Dropzone';

import { createCase, updateCaseAdmin } from '../../../../../actions/casesActions';
import { setNotification } from '../../../../../actions/notificationActions';

import { sortAlphabetically } from '../../../../../util/utils';

import { userRoles, caseConstants } from '../../../../../constants';
import { fileTypes } from '../../../../../constants/cases';

const { procedureFlows } = caseConstants;

const Step0StaticAdmin = (props) => {
  const {
    uid,
    userRole,
    surgeons,
    hospitals,
    procedures,
    surgeonList,
    distributors,
    activeCase,
    updateCaseAdmin,
    caseDistributor,
  } = props;

  const startDate = moment().add(28, 'days');

  const findSurgeonDistributor = useMemo(
    () => surgeonList.find((surgeon) => surgeon.distributor === activeCase?.distributorId),
    [surgeonList, activeCase?.distributorId]
  );
  const findAssignedSurgeon = useMemo(
    () => surgeons.find((surgeon) => surgeon.uid === findSurgeonDistributor?.userId),
    [surgeons, findSurgeonDistributor?.userId]
  );

  const findSurgeon = () => {
    const _surgeon = surgeonList.find((item) => activeCase.surgeonId === item.id);
    if (_surgeon) {
      return surgeons.find((item) => item.uid === _surgeon.userId);
    } else {
      return null;
    }
  }

  const [surgeon, setSurgeon] = useState(
    findSurgeon() || findAssignedSurgeon || null
  );
  const [distributor, setDistributor] = useState(caseDistributor || null);
  const [hospital, setHospital] = useState(
    hospitals.find((item) => item.name === activeCase.hospital) || null
  );
  const [procedure, setProcedure] = useState(
    procedures.find((item) => activeCase.procedure === item.id) || null
  );
  const [filteredHospitals, setHospitals] = useState(hospitals);
  const [date, setDate] = useState(activeCase.date ? moment(activeCase.date) : startDate);
  const [dateTBC, setDateTBC] = useState(!activeCase.date);

  const [firstName, setFirstName] = useState(activeCase.patientInfo.firstName || '');
  const [lastName, setLastName] = useState(activeCase.patientInfo.lastName || '');
  const [gender, setGender] = useState(activeCase.patientInfo.gender || 'male');
  const [dateOfBirth, setDateOfBirth] = useState(
    moment(activeCase.patientInfo.dateOfBirth) || moment('Jan 1, 1990')
  );
  const [procedureSide, setProcedureSide] = useState(activeCase.procedureSide || 'left');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [planningFileRequired, setPlanningFileRequired] = useState(activeCase.planningFileRequired ? 'yes' : 'no');
  const [consultationDate, setConsultationDate] = useState(activeCase.consultationDate ? moment(activeCase.consultationDate) : moment().add(7, 'days'));

  const [isPaid, setPaid] = useState(activeCase?.isPaid || false);

  const [reports, setReports] = useState([]);

  const checkMissingFields = () => {
    return (
      !distributor ||
      !surgeon ||
      !hospital ||
      !procedure ||
      !firstName ||
      !lastName ||
      !gender ||
      !dateOfBirth ||
      !procedureSide
    );
  };

  // const getDistributorCountry = () => {
  //   if (surgeon && surgeon.distributor) {
  //     return distributors.find((distributor) => distributor.id === surgeon.distributor).country;
  //   } else {
  //     return 'Australia';
  //   }
  // };

  const onUpload = (files) => {
    setReports([...reports, ...files.filter((file) => !reports.map((sheet) => sheet.name).includes(file.name))]);
  };

  const onRemove = (name) => {
    setReports(reports.filter((file) => file.name !== name));
  };

  const handleSubmit = () => {
    if (checkMissingFields()) {
      setError(true);
      return;
    }

    const now = moment().format();
    let currentSurgeon = surgeon;

    if (userRole === userRoles.SURGEON.name) {
      currentSurgeon = surgeons.find((user) => user.uid === uid);
    }

    const files = [...activeCase.files];
    const newFiles = [];

    reports.forEach((file) => {
      files?.unshift({
        id: `${activeCase.id}_${fileTypes.report}_${file.name}`,
        name: file.name,
        type: fileTypes.report,
        date: now,
        userId: uid,
      });
      newFiles?.push(file);
    });

    const caseData = {
      surgeon: activeCase.surgeon.id,
      distributorId: distributor.id,
      hospital: hospital.name,
      hospitalUniq: { id: hospital.id, name: hospital.name },
      procedure: procedure.id,
      procedureName: procedure.name,
      procedureFlow: procedure.flow || activeCase.procedureFlow || '',
      date: date && !dateTBC ? date.format() : null,
      procedureSide,
      patientInfo: {
        firstName,
        lastName,
        gender,
        dateOfBirth: moment.isMoment(dateOfBirth) ? dateOfBirth.format('ll') : dateOfBirth,
      },
      patientLastName: lastName,
      isPaid,
      files,
      planningFileRequired: planningFileRequired === 'yes',
      consultationDate: planningFileRequired === 'yes' && consultationDate ? consultationDate.format() : null
    };

    if (date && !dateTBC) {
      caseData.postponed = false;
    }

    setLoading(true);
    updateCaseAdmin(
      activeCase.id,
      { ...activeCase, ...caseData },
      { hospital: activeCase.hospital, date: activeCase.date, surgeon: activeCase.surgeon, procedure: activeCase.procedure },
      newFiles,
    ).then(() => {
      setLoading(false);
    });
  };

  const getFilteredHospitals = (user) => {
    const currentSurgeon = user
      ? surgeonList
          .filter((item) => item.userId === user.uid)
          .find((surgeon) => surgeon.distributor === distributor?.id)
      : null;

    if (!currentSurgeon) {
      return hospitals;
    }

    return hospitals.filter((item) => currentSurgeon.hospitals.includes(item.id));
  };

  const handleSurgeonChange = (user) => {
    const filteredHospitals = getFilteredHospitals(user);

    setSurgeon(user);
    setHospitals(filteredHospitals);
    setHospital(filteredHospitals.length ? filteredHospitals[0] : null);
  };

  useEffect(() => {
    const newSurgeon = surgeonList.filter((s) => s.distributor === distributor?.id);
    const findSurgeon = newSurgeon.find((s) => s.userId === surgeon?.uid);
    const user = surgeons.find((s) => s.uid === findSurgeon?.userId);

    const filteredHospitals = getFilteredHospitals(user);

    setHospitals(filteredHospitals);
  }, [surgeon, distributor, hospital]);

  const filteredProcedures = useMemo(
    () =>
      procedures.filter((item) =>
        distributor?.assignedProcedures?.find((assign) => assign?.id === item?.id)
      ),
    [procedures, distributor]
  );

  useEffect(() => {
    setProcedure(filteredProcedures.find((item) => activeCase.procedure === item.id) || null);
  }, [activeCase.procedure, filteredProcedures]);

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="modal-item-content">
            <div className="modal-item-block" style={{ marginTop: 0 }}>
              <div className="block-header">Case info</div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <div className="field-container">
                    <div className="field-title">Distributor</div>
                    <div className="field-content">
                      <div className="step0-surgeon-name">
                        {distributor.name}
                      </div>
                      {/*<Select*/}
                      {/*  value={distributor}*/}
                      {/*  fullWidth*/}
                      {/*  disabled*/}
                      {/*  className="case-field-select"*/}
                      {/*  onChange={(e) => setDistributor(e.target.value)}*/}
                      {/*>*/}
                      {/*  {distributors.sort(sortAlphabetically('name')).map((item) => (*/}
                      {/*    <MenuItem key={item.id} value={item}>*/}
                      {/*      {item.name}*/}
                      {/*    </MenuItem>*/}
                      {/*  ))}*/}
                      {/*</Select>*/}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="field-container">
                    <div className="field-title">Assigned surgeon</div>
                    <div className="field-content">
                      {surgeon && activeCase.step > 3 ? (
                        <div className="step0-surgeon-name">
                          Dr. {surgeon.firstName} {surgeon.lastName}
                        </div>
                      ) : (
                        <UserSelect
                          onChange={handleSurgeonChange}
                          value={surgeon}
                          userList={surgeons}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="field-container">
                    <div className="field-title">Hospital</div>
                    <div className="field-content">
                      <Select
                        value={hospital}
                        fullWidth
                        className="case-field-select"
                        onChange={(e) => setHospital(e.target.value)}
                      >
                        {filteredHospitals.sort(sortAlphabetically('name')).map((item) => (
                          <MenuItem key={item.id} value={item}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  {activeCase?.step < 4 ? (
                    <div className="field-container">
                      <div className="field-title">Procedure</div>
                      <div className="field-content">
                        <Select
                          value={procedure}
                          fullWidth
                          className="case-field-select"
                          onChange={(e) => setProcedure(e.target.value)}
                        >
                          {filteredProcedures.sort(sortAlphabetically('name')).map((item) => (
                            <MenuItem key={item.id} value={item}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  ) : (
                    <div className="field-container">
                      <div className="field-title">Procedure</div>
                      <div className="field-content">
                        <div className="step0-surgeon-name">
                          {procedure.name}
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="field-container">
                    <div className="field-title">Targeted surgery date</div>
                    <div className="field-content">
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="DD/MM/YYYY"
                          fullWidth
                          id="date-picker"
                          value={date}
                          onChange={(date) => setDate(date)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          disabled={dateTBC}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={dateTBC} onChange={(e) => setDateTBC(e.target.checked)} />}
                        label="TBC (if date not yet confirmed)"
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="modal-item-block">
              <div className="block-header">Patient info</div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <div className="field-container">
                    <div className="field-title">Patient name (name, surname)</div>
                    <div className="field-content d-flex">
                      <div className="flex-1">
                        <TextField
                          id="firstName"
                          // inputProps={{ maxLength: 1 }}
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="m-l-lg flex-1">
                        <TextField
                          id="lastName"
                          type="text"
                          fullWidth
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="field-container">
                    <div className="field-title">Gender</div>
                    <div className="field-content">
                      <div className="radio-group-container">
                        <RadioGroup
                          aria-label="gender"
                          name="gender"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <FormControlLabel
                            value="male"
                            control={<Radio color="primary" />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="female"
                            control={<Radio color="primary" />}
                            label="Female"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="field-container">
                    <div className="field-title">Date of birth</div>
                    <div className="field-content">
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          variant="inline"
                          format="DD/MM/YYYY"
                          fullWidth
                          id="date-picker"
                          value={dateOfBirth}
                          onChange={(date) => setDateOfBirth(date)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          disableFuture
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </Grid>
                {activeCase?.procedureFlow !== procedureFlows.CMF && activeCase?.procedureFlow !== procedureFlows.CMF_EXTENDED && activeCase?.procedureFlow !== procedureFlows.ONCOL && activeCase?.procedureFlow !== procedureFlows.CUSTOM_EXTENDED && activeCase?.procedureFlow !== procedureFlows.DOUBLE_LEVEL_OSTEOTOMY && (
                  <Grid item xs={12} md={6}>
                    <div className="field-container">
                      <div className="field-title">Procedure side</div>
                      <div className="field-content">
                        <div className="radio-group-container">
                          <RadioGroup
                            aria-label="procedureSide"
                            name="procedureSide"
                            value={procedureSide}
                            onChange={(e) => setProcedureSide(e.target.value)}
                          >
                            <FormControlLabel
                              value="left"
                              control={<Radio color="primary" />}
                              label="Left"
                            />
                            <FormControlLabel
                              value="right"
                              control={<Radio color="primary" />}
                              label="Right"
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>

            <div>
              <div className="modal-item-block">
                <div className="block-header">Surgeon consultation report</div>
                <Dropzone
                  files={reports}
                  onUpload={onUpload}
                  icon="scan"
                  onRemove={onRemove}
                />
              </div>
            </div>

            {[procedureFlows.HTO, procedureFlows.HTO_LATERAL, procedureFlows.DFO_Medial, procedureFlows.DFO_Medial, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY, procedureFlows.ACL, procedureFlows.CMF_EXTENDED].includes(activeCase.procedureFlow) && (
              <div className="m-t-lg">
                <div className="block-header">Planning File</div>
                <div className='secondary'>Is planning file required for patient consultation?</div>

                <Grid item xs={12} md={6}>
                  <div className="radio-group-container">
                    <RadioGroup
                      aria-label="planningFileRequired"
                      name="planningFileRequired"
                      value={planningFileRequired}
                      onChange={(e) => setPlanningFileRequired(e.target.value)}
                    >
                      <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                      <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    </RadioGroup>
                  </div>
                </Grid>
                {planningFileRequired === 'yes' && (
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="DD/MM/YYYY"
                        fullWidth
                        id="date-picker"
                        value={consultationDate}
                        onChange={(date) => setConsultationDate(date)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                )}
              </div>
            )}


            {activeCase?.procedureFlow === procedureFlows.ACL && (
              <div className="modal-item-block">
                <div className="block-header">Patient status</div>
                <div className="field-container">
                  <div className="m-t-sm font-bold secondary">Please check the box to confirm that payment has been received for this case</div>
                  <div className="field-content">
                    <FormControlLabel
                      control={<Checkbox color="primary" checked={isPaid} onChange={(e) => setPaid(e.target.checked)} />}
                      label="Payment Received"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="case-modal-actions">
        <div />
        <Button
          text="Save"
          loading={loading}
          disabled={loading}
          color="primary"
          onClick={handleSubmit}
        />
      </div>
      <Alert
        variant="warning"
        message="All fields are required"
        open={error}
        onClose={() => setError(false)}
      />
    </div>
  );
};

Step0StaticAdmin.propTypes = {
  onClose: PropTypes.func,
  uid: PropTypes.string,
  userRole: PropTypes.string,
  surgeons: PropTypes.array,
  hospitals: PropTypes.array,
  procedures: PropTypes.array,
  createCase: PropTypes.func,
};

const mapStateToProps = (state) => {
  const uid = state.user.currentUser.uid;
  const userRole = state.user.currentUser.role;
  const hospitals = state.hospitals.list;
  const surgeonList = state.surgeons.list?.filter((s) => !s.disabled);
  const distributors = state.distributors.list;
  const activeCase = state.cases.activeCase;

  let surgeons = state.users.list.filter((user) => user.role === userRoles.SURGEON.name);

  const currentSurgeon = surgeonList.find((item) => item.id === uid);
  const surgeon = userRole === userRoles.SURGEON.name && surgeons.find((user) => user.uid === uid);

  const caseDistributor = distributors.find(
    (distributor) => distributor.id === activeCase?.distributorId
  );

  if (userRole === userRoles.SALES_REP.name) {
    const filteredSurgeons = surgeonList
      .filter((item) => item.salesRep.includes(uid))
      .map((item) => item.userId);

    surgeons = state.users.list.filter((user) => filteredSurgeons.includes(user.uid));
  }
  if (userRole === userRoles.PRACTICE_MANAGER.name) {
    const filteredSurgeons = surgeonList
      .filter((item) => item.manager.includes(uid))
      .map((item) => item.userId);

    surgeons = state.users.list.filter((user) => filteredSurgeons.includes(user.uid));
  }

  const hospitalList =
    surgeon && currentSurgeon
      ? hospitals.filter((item) => {
          return surgeonList
            .filter((s) => s.userId === uid)
            .some((i) => i.hospitals.includes(item.id));
        })
      : hospitals;

  return {
    uid,
    userRole,
    surgeons,
    distributors,
    activeCase,
    caseDistributor,
    hospitals: hospitalList,
    procedures: state.procedures.list,
    surgeonList: state.surgeons.list,
    currentSurgeon: surgeon,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCase: (uid, caseId, stepData) => dispatch(createCase(uid, caseId, stepData)),
    setNotification: (notification) => dispatch(setNotification(notification)),
    updateCaseAdmin: (id, data, previousData, files) => dispatch(updateCaseAdmin(id, data, previousData, files)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step0StaticAdmin);
