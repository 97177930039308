import React, { useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import WarningIcon from '@material-ui/icons/Warning';

import RestrictComponent from '../../../../shared/RestrictComponent';
import UserSelect from '../../../../shared/UserSelect';

import { sortAlphabetically } from '../../../../../util/utils';

import { userRoles, caseConstants } from '../../../../../constants';

import pdfIcon from '../../../../../assets/preop_icon.svg';
import videoIcon from '../../../../../assets/block_icon.svg';
import imageIcon from '../../../../../assets/image_icon.svg';
import Dropzone from '../../../../shared/dropzone/Dropzone';
import { selectedProcedureFlows } from '../../../../../constants/cases';

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));

const { GLOBAL_ADMIN, SALES_REP, PRACTICE_MANAGER, SURGEON, COUNTRY_ADMIN, REGISTRAR, ENGINEER } = userRoles;
const { procedureFlows } = caseConstants;

const getColor = (procedureFlow) => {
  if (procedureFlow === procedureFlows.ONCOL) {
    return "#7266FA";
  }
  if (procedureFlow === procedureFlows.CUSTOM_EXTENDED) {
    return "#217CEE";
  }
  if (procedureFlow === procedureFlows.DOUBLE_LEVEL_OSTEOTOMY) {
    return "#ee9321";
  }
  return "#33CC99";
};

export const Step0CaseInfo = ({
  distributors,
  distributor,
  setDistributor,
  selectedFlow,
  surgeons,
  setSurgeon,
  procedures,
  hospitals,
  currentSurgeon,
  surgeon,
  surgeonList,
  hospital,
  setHospital,
  documentList,
  procedure,
  setProcedure,
  date,
  setDate,
  isAdmin,
  psi,
  startDate,
  setPsi,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  gender,
  setGender,
  dateOfBirth,
  setDateOfBirth,
  procedureSide,
  setProcedureSide,
  checked,
  setChecked,
  reports = [],
  caseNote,
  setCaseNote,
  onUpload,
  onRemove,
  dateTBC,
  setDateTBC,
  leadDays,
  planningFileRequired,
  setPlanningFileRequired,
  consultationDate,
  setConsultationDate
}) => {
  const classes = useStyles();

  const [filteredHospitals, setHospitals] = useState(currentSurgeon ? hospitals : []);

  const handleDistributorChange = (e) => {
    setDistributor(e.target.value);

    if (!currentSurgeon) {
      setSurgeon(null);
      setHospitals([]);
    }

    setHospital(null);
    setProcedure(null);
  };

  const getIcon = (name) => {
    if (name && name.includes('.pdf')) {
      return pdfIcon;
    }
    if (name && name.includes('.mp4')) {
      return videoIcon;
    }
    return imageIcon;
  };

  const filteredSurgeons = useMemo(
    () =>
      surgeonList
        .filter((item) => item.distributor === distributor?.id)
        .map((surgeon) => surgeons.find((s) => s.uid === surgeon.userId))
        .filter((surgeon) => !!surgeon),
    [surgeonList, surgeons, distributor]
  );

  const getFilteredHospitals = (user) => {
    const currentSurgeon = user
      ? surgeonList
          .filter((item) => item.userId === user.uid)
          .find((surgeon) => surgeon.distributor === distributor?.id)
      : null;

    if (!currentSurgeon) {
      return hospitals;
    }

    return hospitals.filter((item) => currentSurgeon.hospitals.includes(item.id));
  };

  const handleSurgeonChange = (user) => {
    const filteredHospitals = getFilteredHospitals(user);

    setSurgeon(user);
    setHospitals(filteredHospitals);
    setHospital(filteredHospitals.length ? filteredHospitals[0] : null);
  };

  const filteredProcedures = useMemo(
    () =>
      procedures.filter((item) =>
        distributor?.assignedProcedures?.find((assign) => assign?.id === item?.id) && (
          item?.flow === selectedFlow || item?.flow?.includes(selectedFlow)
        )
      ),
    [procedures, distributor, selectedFlow]
  );

  useEffect(() => {
    const newSurgeon = surgeonList.filter((s) => s.distributor === distributor?.id);
    const findSurgeon = newSurgeon.find((s) => s.userId === surgeon?.uid);
    const user = surgeons.find((s) => s.uid === findSurgeon?.userId);

    const filteredHospitals = getFilteredHospitals(user);

    setHospitals(filteredHospitals);
  }, [surgeon, distributor, hospital]);

  return (
    <div className="case-step-row">
      <div className="column">
        <div className="modal-item-circle" />
      </div>
      <div className="modal-item-content">
        <div>New Case</div>
        <div className="modal-item-block">
          <div className="block-header">Case info</div>

          <Grid container spacing={3}>
            <RestrictComponent roles={[GLOBAL_ADMIN, SURGEON, SALES_REP, PRACTICE_MANAGER, COUNTRY_ADMIN, REGISTRAR, ENGINEER]}>
              {currentSurgeon && distributors?.length > 1 && (
                <Grid item xs={12} md={12}>
                  <div className="field-container">
                    <div className="field-title">Distributor</div>
                    <div className="field-content">
                      <Select
                        value={distributor}
                        fullWidth
                        className="case-field-select"
                        onChange={(e) => handleDistributorChange(e)}
                      >
                        {distributors.sort(sortAlphabetically('name')).map((item) => (
                          <MenuItem key={item.id} value={item}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </Grid>
              )}
              {!currentSurgeon && (
                <Grid item xs={12} md={12}>
                  <div className="field-container">
                    <div className="field-title">Distributor</div>
                    <div className="field-content">
                      <Select
                        value={distributor}
                        fullWidth
                        className="case-field-select"
                        onChange={(e) => handleDistributorChange(e)}
                      >
                        {distributors.sort(sortAlphabetically('name')).map((item) => (
                          <MenuItem key={item.id} value={item}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </Grid>
              )}
            </RestrictComponent>

            <Grid item xs={12} md={6}>
              <div className="field-container">
                <div className="field-title">Assigned surgeon</div>
                <div className="field-content">
                  {currentSurgeon ? (
                    <div className="step0-surgeon-name">
                      Dr. {currentSurgeon.firstName} {currentSurgeon.lastName}
                    </div>
                  ) : (
                    <UserSelect onChange={handleSurgeonChange} value={surgeon} userList={filteredSurgeons} />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="field-container">
                <div className="field-title">Hospital</div>
                <div className="field-content">
                  <Select
                    value={hospital}
                    fullWidth
                    className="case-field-select"
                    onChange={(e) => setHospital(e.target.value)}
                  >
                    {filteredHospitals.sort(sortAlphabetically('name')).map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="field-container">
                <div className="field-title">Procedure</div>
                <div className="field-content">
                  <Select
                    value={procedure}
                    fullWidth
                    className="case-field-select"
                    onChange={(e) => setProcedure(e.target.value)}
                  >
                    {filteredProcedures.sort(sortAlphabetically('name')).map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="field-container">
                <div className="field-title">Targeted surgery date</div>
                <div className="field-content">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      fullWidth
                      id="date-picker"
                      value={date}
                      onChange={(date) => setDate(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      minDate={(isAdmin || psi === 'no' || !!dateTBC) ? undefined : startDate}
                      minDateMessage={
                        !isAdmin && !dateTBC && `Surgery date must have a minimum of ${leadDays} days`
                      }
                      disabled={dateTBC}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={dateTBC} onChange={(e) => setDateTBC(e.target.checked)} />}
                    label="TBC (if date not yet confirmed)"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {selectedFlow !== procedureFlows.ACL && selectedFlow !== procedureFlows.CMF && selectedFlow !== procedureFlows.CMF_EXTENDED && selectedFlow !== procedureFlows.ONCOL && selectedFlow !== procedureFlows.CUSTOM_EXTENDED && selectedFlow !== procedureFlows.DOUBLE_LEVEL_OSTEOTOMY && (
          <RestrictComponent roles={[userRoles.GLOBAL_ADMIN, userRoles.SALES_REP, userRoles.COUNTRY_ADMIN]}>
            <div className="modal-item-block">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div className="field-container">
                    <div className="field-title">PSI required for this case</div>
                    <div className="field-content">
                      <div className="radio-group-container">
                        <RadioGroup aria-label="psi" name="psi" value={psi} onChange={(e) => setPsi(e.target.value)}>
                          <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                          <FormControlLabel value="no" control={<Radio color="primary" />} label="No (skip)" />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </RestrictComponent>
        )}
        <div className="modal-item-block">
          <div className="block-header">Patient info</div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div className="field-container">
                <div className="field-title">Patient name (name, surname)</div>
                <div className="field-content d-flex">
                  <div className="flex-1">
                    <TextField
                      id="firstName"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="m-l-lg flex-1">
                    <TextField
                      id="lastName"
                      type="text"
                      fullWidth
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="field-container">
                <div className="field-title">Gender</div>
                <div className="field-content">
                  <div className="radio-group-container">
                    <RadioGroup
                      aria-label="gender"
                      name="gender"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <FormControlLabel value="male" control={<Radio color="primary" />} label="Male" />
                      <FormControlLabel value="female" control={<Radio color="primary" />} label="Female" />
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="field-container">
                <div className="field-title">Date of birth</div>
                <div className="field-content">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      variant="inline"
                      format="DD/MM/YYYY"
                      fullWidth
                      id="date-picker"
                      value={dateOfBirth}
                      onChange={(date) => setDateOfBirth(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disableFuture
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </Grid>
            {selectedFlow !== procedureFlows.CMF && selectedFlow !== procedureFlows.CMF_EXTENDED && (
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Procedure side</div>
                  <div className="field-content">
                    <div className="radio-group-container">
                      <RadioGroup
                        aria-label="procedureSide"
                        name="procedureSide"
                        value={procedureSide}
                        onChange={(e) => setProcedureSide(e.target.value)}
                      >
                        <FormControlLabel value="left" control={<Radio color="primary" />} label="Left" />
                        <FormControlLabel value="right" control={<Radio color="primary" />} label="Right" />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>

          {[procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(selectedFlow) && (
            <div className="m-t-lg d-flex align-center">
              <WarningIcon style={{ color: getColor(selectedFlow) }}/>
              <div className="m-l-sm" style={{ color: getColor(selectedFlow) }}>Custom case note or consultation report required to proceed</div>
            </div>
          )}

          <div className="">
            <div className="modal-item-block">
              <div className="block-header">Case note</div>
              <TextField
                multiline
                id="caseNote"
                type="text"
                placeholder="Type any case related notes here"
                value={caseNote}
                onChange={(e) => setCaseNote(e.target.value)}
                minRows={3}
                variant="outlined"
              />
            </div>
          </div>

          {[selectedProcedureFlows.HTO, selectedProcedureFlows.DFO, selectedProcedureFlows.DOUBLE_LEVEL_OSTEOTOMY, selectedProcedureFlows.ACL, selectedProcedureFlows.CMF_EXTENDED].includes(selectedFlow) && (
            <div className="m-t-lg">
              <div className="block-header">Planning File</div>
              <div className='secondary'>Do you require a planning file to present to the patient prior to surgery?</div>

              <Grid item xs={12} md={6}>
                <div className="radio-group-container">
                  <RadioGroup
                    aria-label="planningFileRequired"
                    name="planningFileRequired"
                    value={planningFileRequired}
                    onChange={(e) => setPlanningFileRequired(e.target.value)}
                  >
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                  </RadioGroup>
                </div>
              </Grid>
              {planningFileRequired === 'yes' && (
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      fullWidth
                      id="date-picker"
                      value={consultationDate}
                      onChange={(date) => setConsultationDate(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
            </div>
          )}

          <div>
            <div className="modal-item-block">
              <div className="block-header">
                {`Surgeon consultation report${selectedFlow === procedureFlows.CMF ? ' (recommended)' : ''}`}
              </div>
              <Dropzone
                files={reports}
                onUpload={onUpload}
                icon="scan"
                onRemove={onRemove}
              />
            </div>
          </div>

          {!!documentList.length && (
            <div>
              <div style={{ margin: '16px 0' }}>
                <div className="secondary uppercase font-size-xs m-b-md">Documents</div>
                <div className="document-list">
                  {documentList.map((document) => (
                    <div key={document.id} className="m-b-sm">
                      <img src={getIcon(document.name)} alt="file-icon" style={{ marginRight: '8px', width: '20px' }} />
                      <a className={classes.primary} href={document.url} target="_blank" rel="noopener noreferrer">
                        {document.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <FormControlLabel
                control={<Checkbox color="primary" checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
                label="I have reviewed all relevant procedure documentation"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

