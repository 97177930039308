import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import HtoSummary from '../../step4/hto-flow/HtoSummary';
import DfoSummary from '../../step4/dfo-flow/DfoSummary';

import HardwareList from '../../HardwareList';
import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';

import Button from '../../../../../shared/Button';
import Dropzone from '../../../../../shared/dropzone/Dropzone';

export const OsteotomyStep5 = (props) => {
  const {
    classes,
    onClose,
    activeCase,
    updateCase,
    products,
    preopPlan,
    preopRejected,
    manufacturingFiles,
    manufacturingFilesRejected,
    caseHardware,
    caseManufacturing,
    fileStatuses,
    fileTypes,
    statuses,
    procedureFlows,
    caseNotifications,
    userId,
  } = props;
  const { alignmentData } = activeCase;

  const [approval, setApproval] = useState('approve');
  const [notes, setNotes] = useState('');
  const [preop, setPreop] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [hardwareList, setHardwareList] = useState(caseHardware ? caseHardware : []);
  const [manufacturingList, setManufacturingList] = useState(caseManufacturing ? caseManufacturing : []);

  const requiredDate = activeCase?.date ? moment(activeCase?.date).subtract(7, 'd') : moment();
  const [date, setDate] = useState(requiredDate);

  const handleBlocksUpload = (files) => {
    const newFiles = [];

    files.forEach((file) => {
      const newFile = new File([file], `b-${file.name}`, { type: file.type });

      newFiles.push(newFile);
    });

    setBlocks([...blocks, ...newFiles.filter((file) => !blocks.map((block) => block.name).includes(file.name))]);
  };

  const handleRemovePreop = (name) => {
    setPreop(preop.filter((file) => file.name !== name));
  };

  const handleRemoveBlock = (name) => {
    setBlocks(blocks.filter((file) => file.name !== name));
  };

  const handleAddHardware = (product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setHardwareList(list);
    } else {
      setHardwareList([...hardwareList, product]);
    }
  };

  const handleRemoveHardware = (id) => {
    setHardwareList(hardwareList.filter((item) => item.id !== id));
  };

  const handleAmountChange = (id, amount) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setHardwareList(list);
  };

  const handleCategoryChange = (id, category) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setHardwareList(list);
  };

  const handleChange = (id, product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setHardwareList(list);
  };

  const handleAddManufacturing = (product) => {
    const list = [...manufacturingList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setManufacturingList(list);
    } else {
      setManufacturingList([...manufacturingList, product]);
    }
  };

  const handleRemoveManufacturing = (id) => {
    setManufacturingList(manufacturingList.filter((item) => item.id !== id));
  };

  const handleAmountManufacturingChange = (id, amount) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.amount = amount;
    setManufacturingList(list);
  };

  const handleCategoryManufacturingChange = (id, category) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.category = category;
    setManufacturingList(list);
  };

  const handleManufacturingChange = (id, product) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.product = product;
    setManufacturingList(list);
  };

  const handleSubmit = () => {
    const now = moment().format();

    if (approval === 'reject' && !notes.length) {
      return;
    }

    if (approval === 'approve') {
      const activity = [
        { ...caseNotifications[5].success, date: now, userId },
        { ...caseNotifications[5].file, date: now, userId },
        ...activeCase.activity,
      ];

      const updatedData = { ...alignmentData, wedgeApproved: approval === 'approve' };
      const files = [...activeCase.files];
      const newFiles = [];
      const preopFile = preop[0];

      if (preopFile) {
        files.unshift({
          id: `${activeCase.id}_${fileTypes.preop}_${preopFile.name}`,
          name: preopFile.name,
          type: fileTypes.preop,
          status: fileStatuses.PENDING,
          date: now,
          userId,
        });
        newFiles.push(preopFile);
      }

      blocks.forEach((file) => {
        files.unshift({
          id: `${activeCase.id}_${fileTypes.manufacturing}_${file.name}`,
          name: file.name,
          type: fileTypes.manufacturing,
          status: fileStatuses.PENDING,
          date: now,
          userId,
        });
        newFiles.push(file);
      });

      const approvedPreop = files.find(
        (file) => file.type === fileTypes.preop && file.status === fileStatuses.APPROVED
      );

      updateCase(
        {
          ...activeCase,
          step: approvedPreop ? 7 : 6,
          activity,
          hardware: hardwareList.map((item) => ({
            product: item.product,
            amount: item.amount,
            category: item.category,
          })),
          manufacturing: manufacturingList.map((item) => ({
            product: item.product,
            amount: item.amount,
            category: item.category,
          })),
          alignmentData: updatedData,
          files,
          completionDates: { ...activeCase.completionDates, step5: now },
          completedBy: { ...activeCase.completedBy, step5: userId },
          status: statuses.inProgress,
          notes: {
            ...activeCase.notes,
            step5: notes,
          },
          requiredDate: date.format(),
        },
        newFiles
      ).then(() => onClose());
    } else {
      const activity = [{ ...caseNotifications[5].reject, date: now, userId }, ...activeCase.activity];
      const alignmentData = { ...activeCase.alignmentData, measurementsRejected: true };

      updateCase(
        {
          ...activeCase,
          step: 4,
          activity,
          alignmentData,
          notes: { ...activeCase.notes, step5: notes },
          status: statuses.rejected,
        },
        null,
        true
      ).then(() => onClose());
    }
  };

  const renderSummaryView = (flow) => {
    if (flow === procedureFlows.HTO || flow === procedureFlows.HTO_LATERAL) {
      return (
        <HtoSummary
          classes={classes}
          type={alignmentData.type}
          HKA={alignmentData['Pre-Op_HKA']}
          initialTibialWidth={alignmentData['%WBL']}
          DCA={alignmentData['D_CA']}
          tibialWidth={alignmentData['Tibial_Width']}
          RCA={alignmentData['R_CA']}
          initialPTS={alignmentData['Pre_TS']}
          PTS={alignmentData['Post_TS']}
          WH={alignmentData['WH']}
          wedgePosterior={alignmentData['Wedge_posterior']}
          wedgeAnterior={alignmentData['Wedge_anterior']}
          wedgeIncluding={alignmentData.wedgeIncluding ? 'Include' : 'Remove'}
        />
      );
    }

    if (flow === procedureFlows.DFO_Lateral || flow === procedureFlows.DFO_Medial) {
      return (
        <DfoSummary
          classes={classes}
          type={alignmentData.type}
          flow={activeCase.procedureFlow}
          HKA={alignmentData['Pre-Op_HKA']}
          initialTibialWidth={alignmentData['%WBL']}
          DCA={alignmentData['D_CA']}
          tibialWidth={alignmentData['Tibial_Width']}
          RCA={alignmentData['R_CA']}
          initialPTS={alignmentData['Pre_TS']}
          PTS={alignmentData['Post_TS']}
          WH={alignmentData['WH']}
          wedgePosterior={alignmentData['Wedge_posterior']}
          wedgeAnterior={alignmentData['Wedge_anterior']}
          wedgeIncluding={alignmentData.wedgeIncluding ? 'Include' : 'Remove'}
        />
      );
    }

    if (flow === procedureFlows.CUSTOM) {
      return null;
    }
  };

  const rejected = approval === 'reject';
  const disabled = rejected && !notes.length;

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">5</div>
          </div>
          <div className="modal-item-content">
            <div className="m-b-lg">Review Measurements</div>
            {activeCase.access ? (
              <div>
                {renderSummaryView(activeCase.procedureFlow)}

                {(activeCase?.procedureFlow !== procedureFlows.CUSTOM) && (
                  <>
                    <div className="radio-group-container d-flex flex-end">
                      <RadioGroup
                        aria-label="calculationType"
                        name="calculationType"
                        value={approval}
                        onChange={(e) => setApproval(e.target.value)}
                      >
                        <FormControlLabel value="approve" control={<Radio color="primary" />} label="Approve" />
                        <FormControlLabel value="reject" control={<Radio color="primary" />} label="Reject" />
                      </RadioGroup>
                    </div>
                    {rejected && (
                      <TextField
                        id="notes"
                        type="text"
                        fullWidth
                        multiline
                        label="Notes"
                        placeholder="Add reason for reject here"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    )}
                  </>
                )}
                <div className="m-t-lg m-b-md">
                  <div className="secondary font-size-sm m-b-sm">ATTACH PRE-OP PLAN (.pdf)</div>
                  {preopRejected && (
                    <div className="alert warning m-b-md">
                      <div className="d-flex align-center">
                        <WarningIcon color="primary" />
                        <div>Pre-Op plan has been rejected</div>
                      </div>
                      {activeCase.notes && activeCase.notes.step6 && (
                        <div className="m-t-md">Note: {activeCase.notes.step6}</div>
                      )}
                    </div>
                  )}
                  <Dropzone
                    files={preop}
                    onUpload={setPreop}
                    type="pdf"
                    icon="preop"
                    multiple={false}
                    disabled={manufacturingFilesRejected}
                    onRemove={handleRemovePreop}
                  />
                </div>
                <div className="m-t-lg m-b-md">
                  <div className="secondary font-size-sm m-b-sm">ATTACH BLOCK MANUFACTURING FILES</div>
                  {manufacturingFilesRejected && (
                    <div className="alert warning m-b-md">
                      <div className="d-flex align-center">
                        <WarningIcon color="primary" />
                        <div>Some of the manufacture files have been rejected</div>
                      </div>
                      {activeCase.notes && activeCase.notes.step7 && (
                        <div className="m-t-md">Note: {activeCase.notes.step7}</div>
                      )}
                    </div>
                  )}
                  <Dropzone files={blocks} onUpload={handleBlocksUpload} icon="block" onRemove={handleRemoveBlock} type="zip" />
                </div>
                <div className="m-t-lg">
                  <HardwareList
                    classes={classes}
                    hardwareList={hardwareList}
                    products={products}
                    onAdd={handleAddHardware}
                    onRemove={handleRemoveHardware}
                    onAmountChange={handleAmountChange}
                    onCategoryChange={handleCategoryChange}
                    onChange={handleChange}
                    title="Proposed Hardware"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>
                <div className="m-t-lg">
                  <HardwareList
                    classes={classes}
                    hardwareList={manufacturingList}
                    products={products}
                    onAdd={handleAddManufacturing}
                    onRemove={handleRemoveManufacturing}
                    onAmountChange={handleAmountManufacturingChange}
                    onCategoryChange={handleCategoryManufacturingChange}
                    onChange={handleManufacturingChange}
                    title="PSI Manufacturing"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>

                <div className="hardware-list-container">
                  <div className="hardware-list-header">
                    <div className="secondary font-size-sm">REQUIRED DATE</div>
                  </div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      id="date-picker"
                      value={date}
                      onChange={(date) => setDate(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={5} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button
          text="Submit"
          color="primary"
          onClick={handleSubmit}
          disabled={
            (approval === 'approve' &&
              (!activeCase?.access ||
                (!preopPlan && !preop?.length) ||
                (!manufacturingFiles?.length && !blocks?.length) ||
                !hardwareList?.length)) ||
            disabled
          }
        />
      </div>
    </div>
  );
};
