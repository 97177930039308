import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';

import { caseConstants } from '../../../../../constants';
import { setActiveTab } from '../../../../../actions/casesActions';

import axisDiagramRight from '../../../../../assets/mechanical_axis_diagram_R_flat.svg';
import axisDiagramLeft from '../../../../../assets/mechanical_axis_diagram_L_flat.svg';

import '../../../../modals/case/case-modal.scss';
import { calculationDataCoronal } from '../../../../../constants/cases';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(2),
    fontSize: '1rem',
    whiteSpace: 'pre-line !important',
  },
}));

const MechanicalAxis = ({ activeCase, initialTibialWidth, PTS }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [rangeData, toggleRangeData] = useState(false);

  const { calculationDataCoronal, calculationDataSaggital, procedureSides } = caseConstants;
  const { alignmentData } = activeCase;

  const getRangeClass = (value, min, max) => {
    if (value === min || value === max) {
      return 'orange';
    }
    if (value > min && value < max) {
      return 'green';
    }
    if (value < min || value > max) {
      return 'red';
    }
  };

  const tooltip =
    'Three-dimensional analysis of patient’s preoperative Mechanical Alignment\n' +
    '\n' +
    'Measurement colour codes:\n' +
    '\n' +
    'Green - Within normative range\n' +
    'Orange - Boundary of normative range\n' +
    'Red - Outside normative range\n' +
    '\n' +
    'Image is an animated representation of the resultant deformity correction\n' +
    'only and should not be referenced for any clinical decision making';

  return (
    <div className="modal-item-block flex-1 height-100 margin-0">
      <div className="block-header">Current Alignment</div>
      <div className="d-flex space-between">
        <div className="width-50">
          <div className="alignment-data-item">
            <div>
              <div>HKA (°) '{alignmentData.type === 'Valgus' ? 'Valgus' : 'Varus'}'</div>
              <div className="alignment-data-value">{alignmentData['Pre-Op_HKA']}</div>
            </div>
          </div>
          <div className="alignment-data-item">
            <div>
              <div>
                WBL <span className="secondary">(%)</span>
              </div>
              <div className="alignment-data-value">{initialTibialWidth}</div>
            </div>
          </div>
        </div>
        <div className="d-flex direction-column align-end height-100">
          <FormControlLabel
            checked={rangeData}
            control={<Switch color="primary" />}
            label="Range Data"
            labelPlacement="start"
            onChange={(e) => toggleRangeData(e.target.checked)}
          />
          <div className="d-flex">
            <Tooltip
              title="Files"
              placement="left"
              enterTouchDelay={50}
              classes={{ tooltip: classes.tooltip }}
              leaveTouchDelay={4000}
            >
              <IconButton onClick={() => dispatch(setActiveTab(2))} color="primary">
                <DescriptionIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={tooltip}
              placement="left"
              enterTouchDelay={50}
              classes={{ tooltip: classes.tooltip }}
              leaveTouchDelay={4000}
            >
              <InfoIcon color="primary" height={20} />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="axis-diagram">
        <div className="image-container">
          {activeCase?.procedureSide === procedureSides.left && (
            <img src={axisDiagramLeft} alt="axis-diagram-left" />
          )}
          {activeCase?.procedureSide === procedureSides.right && (
            <img src={axisDiagramRight} alt="axis-diagram-right" />
          )}

          {calculationDataCoronal.map((item) => (
            <div key={item.field} className={`axis-diagram-field ${item.field} ${activeCase?.procedureSide}`}>
              <div>
                <div className="axis-diagram-label">
                  {item.label}:{' '}
                  <span
                    className={`${getRangeClass(alignmentData[item.field], item.min, item.max)}`}
                  >
                    {alignmentData[item.field]}°
                  </span>
                </div>
                {rangeData && (
                  <div className="secondary font-size-xs">
                    ({item.min} - {item.max})
                  </div>
                )}
              </div>
            </div>
          ))}
          {calculationDataSaggital.map((item) => (
            <div key={item.field} className={`axis-diagram-field ${item.field} ${activeCase?.procedureSide}`}>
              <div>
                <div className="axis-diagram-label">
                  {item.label}:{' '}
                  <span
                    className={`${getRangeClass(
                      item.field === 'PPTA' ? PTS : alignmentData[item.field],
                      item.min,
                      item.max
                    )}`}
                  >
                    {item.field === 'PPTA' ? PTS : alignmentData[item.field]}°
                  </span>
                </div>
                {rangeData && item.min && item.max && (
                  <div className="secondary font-size-xs">
                    ({item.min} - {item.max})
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="axis-diagram-labels secondary">
        <div>{activeCase.procedureSide === procedureSides.left ? 'Coronal' : 'Sagittal'}</div>
        <div>{activeCase.procedureSide === procedureSides.left ? 'Sagittal' : 'Coronal'}</div>
      </div>
    </div>
  );
};

MechanicalAxis.propTypes = {
  activeCase: PropTypes.object,
};

export default MechanicalAxis;
