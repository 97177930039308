import React, { Fragment, useState } from 'react';
import moment from 'moment';

import { RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core';

import NoAccess from '../../NoAccess';
import Button from '../../../../../shared/Button';
import CaseAccordion from '../../CaseAccordion';

import { procedureFlows } from '../../../../../../constants/cases';
import { roleNames } from '../../../../../../constants/userRoles';

import preopIcon from '../../../../../../assets/preop_icon.svg';
import calendarIcon from '../../../../../../assets/calendar_icon.svg';

const { GLOBAL_ADMIN, COUNTRY_ADMIN } = roleNames;

export const OsteotomyStep6 = ({
  classes,
  onClose,
  activeCase,
  preop,
  updateCase,
  userRole,
  fileStatuses,
  caseNotifications,
  statuses,
  admin,
  userId,
}) => {
  const [status, setStatus] = useState(fileStatuses.APPROVED);
  const [notes, setNotes] = useState('');

  const { patientInfo, date, hospital, procedureName, procedureFlow } = activeCase;

  const handleSubmit = () => {
    const now = moment().format();
    const activity = [...activeCase.activity];
    const files = [...activeCase.files];
    const preopFile = files.find((file) => file.id === preop.id);

    if (!preopFile) {
      return;
    }

    let rejected = false;

    if (status === fileStatuses.REJECTED) {
      activity.unshift({ ...caseNotifications[6].reject, date: now, userId });
      rejected = true;
    } else {
      activity.unshift({ ...caseNotifications[6].success, date: now, userId });
      rejected = false;
    }

    preopFile.status = status;
    updateCase(
      {
        ...activeCase,
        files,
        step: rejected ? 5 : 7,
        activity,
        completionDates: { ...activeCase.completionDates, step6: now },
        completedBy: { ...activeCase.completedBy, step6: userId },
        notes: { ...activeCase.notes, step6: notes },
        status: rejected ? statuses.rejected : statuses.inProgress,
      },
      rejected
    ).then(() => {
      onClose();
    });
  };

  // const imgSrc = procedureFlow === procedureFlows.DFO_Lateral || procedureFlow === procedureFlows.DFO_Medial ?
  //   "Pre-Op_Front_Page_drop_DFO.png" : "Pre-Op_Front_Page_drop_HTO.png";

  const imgSrc = procedureFlow === procedureFlows.DFO_Lateral || procedureFlow === procedureFlows.DFO_Medial ?
    "Pre-Op_Front_Page_drop_HTO_DFO.png" : "Pre-Op_Front_Page_drop_HTO_DFO.png";

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">6</div>
          </div>
          <div className="modal-item-content">
            <div className="m-b-lg">Approve Pre-Op Plan</div>
            {activeCase.access ? (
              <Fragment>
                {!!preop && (
                  <div className="modal-item-block margin-0">
                    <div className="secondary font-size-sm border-bottom p-b-md m-b-md">
                      Please review the proposed Pre-Op plan below and approve or reject with supporting notes
                    </div>
                    <div className="d-flex space-between">
                      <div>
                        {patientInfo.firstName} {patientInfo.lastName}
                      </div>
                      <div className="d-flex">
                        <img src={calendarIcon} alt="calendar-icon" height={15} className="m-r-sm" />
                        {date ? moment(date).format('DD/MM/YYYY') : 'TBC'}
                      </div>
                    </div>
                    <div className="d-flex space-between m-b-lg">
                      <div>{hospital}</div>
                      <div className={classes.primary}>{procedureName}</div>
                    </div>
                    <div className="block-header">Attached pre-op plan</div>
                    <div className="preop-image-container d-flex flex-center">
                      <a
                        href={preop.downloadLink}
                        target="_blank"
                        className={`${classes.primary} text-center`}
                        rel="noopener noreferrer"
                        download
                      >
                        <img src={imgSrc} alt="preop" style={{ maxHeight: 300 }} width="100%" />
                        <div className=" text-center pointer">Click to view</div>
                      </a>
                    </div>
                    <div className="d-flex p-t-md p-b-md flex-center">
                      <img src={preopIcon} alt="preop-icon" height={20} className="m-r-sm" />
                      <span>{preop.name}</span>
                    </div>
                    <div className="radio-group-container d-flex flex-center">
                      <RadioGroup
                        aria-label="preop-radio"
                        name="preop-radio"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <FormControlLabel
                          value={fileStatuses.APPROVED}
                          control={<Radio color="primary" />}
                          label="Approve"
                        />
                        <FormControlLabel
                          value={fileStatuses.REJECTED}
                          control={<Radio color="primary" />}
                          label="Reject"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                )}
                {status === fileStatuses.REJECTED && (
                  <TextField
                    id="notes"
                    type="text"
                    fullWidth
                    multiline
                    label="Notes"
                    placeholder="Add reason for reject here"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                )}
              </Fragment>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        {(userRole === GLOBAL_ADMIN || userRole === COUNTRY_ADMIN) && <CaseAccordion currentStep={6} />}
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button
          text="Submit"
          color="primary"
          onClick={handleSubmit}
          disabled={!activeCase.access || (status === fileStatuses.REJECTED && !notes.length)}
        />
      </div>
    </div>
  );
};
