import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import moment from 'moment';

import ConfirmationModal from './ConfirmationModal';
import { HtoForm } from '../hto-flow/HtoForm';
import { DfoForm } from '../dfo-flow/DfoForm';
import { NoProcedureFlow } from '../../../../../shared/NoProcedureFlow';
import AlertModal from '../../../../AlertModal';

import { api } from '../../../../../../util';

import { urls, caseConstants, VARUS_DATASET, VALGUS_DATASET } from '../../../../../../constants';
import { fileStatuses, fileTypes} from '../../../../../../constants/cases';

const {
  calculationDataHTO,
  calculationDataDFO,
  validateCalculationData,
  procedureFlows,
  alignmentDataTypes
} = caseConstants;

const delay = 1400;

export const OsteotomyStep3 = ({ onClose, activeCase, updateCase, trainingMode, userId, simpleUpdateCase }) => {
  const isAlignmentType =
    activeCase.procedureFlow.includes('HTO') || activeCase.procedureFlow.includes('DFO') ? null : alignmentDataTypes.varus;

  const [data, setData] = useState({});
  const [planFile, setPlanFile] = useState([]);

  const [validation, setValidation] = useState(validateCalculationData);
  const [type, setType] = useState(activeCase?.alignmentData?.type || isAlignmentType);
  const [loading, toggleLoading] = useState(false);
  const [validationMode, toggleValidation] = useState(false);
  const [open, toggleModal] = useState(false);
  const [calculating, toggleCalculating] = useState({
    'R_%TW': false,
    T_MA: false,
    TW_T_MA: false,
    F_MA: false,
    TW_F_MA: false,
  });

  const [submitModalOpen, toggleSubmitModal] = useState(false);

  const initialTibialWidth = data?.['WBL'] ? Number(((data?.['WBL'] / data?.['TW']) * 100).toFixed(1)) : 0;

  const field = (activeCase?.procedureFlow === procedureFlows.HTO || activeCase?.procedureFlow === procedureFlows.HTO_LATERAL) ? 'PPTA' : 'PDFA';
  const initialPTS = data?.[field] ? (900 - data?.[field] * 10) / 10 : 0;
  const PTS = initialPTS;
  const HKA = data?.['Pre-Op_HKA'] ? data?.['Pre-Op_HKA'] : 0;
  const [DCA, setDCA] = useState(0);
  const [RCA, setRCA] = useState(data?.['Pre-Op_HKA'] || 0);

  useEffect(() => {
    if (activeCase && activeCase.alignmentData) {
      const obj = { ...activeCase.alignmentData };
      if (activeCase.alignmentData.type) {
        delete obj.type;
        setType(activeCase.alignmentData.type);
        toggleValidation(activeCase.isValidated);
      }
      setData(obj);
    }
  }, [activeCase]);

  useEffect(() => {
    calculateRTW(data['%TW'], data['TW']);
  }, [data['%TW'], data['TW']]); // eslint-disable-line
  useEffect(() => {
    calculateTMA(data['JL_A'], data['R_%TW'], data['TW_JL_A']);
  }, [data['JL_A'], data['R_%TW'], data['TW_JL_A']]); // eslint-disable-line
  useEffect(() => {
    calculateTWTMA(data['JL_A'], data['T_MA'], data['TW_JL_A']);
  }, [data['JL_A'], data['T_MA'], data['TW_JL_A']]); // eslint-disable-line
  useEffect(() => {
    calculateFMA(data['JL_CoFH'], data['TW_JL_CoFH'], data['R_%TW']);
  }, [data['JL_CoFH'], data['TW_JL_CoFH'], data['R_%TW']]); // eslint-disable-line
  useEffect(() => {
    calculateTWFMA(data['JL_CoFH'], data['TW_JL_CoFH'], data['F_MA']);
  }, [data['JL_CoFH'], data['TW_JL_CoFH'], data['F_MA']]); // eslint-disable-line
  useEffect(() => {
    calculateFujisawa(data['%TW'], data?.['TW'], data?.['JL_A'], data?.['TW_JL_A'], data?.['JL_CoFH'], data?.['TW_JL_CoFH'], data?.['Pre-Op_HKA']);
  }, [data['%TW'], data?.['TW'], data?.['JL_A'], data?.['TW_JL_A'], data?.['JL_CoFH'], data?.['TW_JL_CoFH'], data?.['Pre-Op_HKA']]);

  const handleChange = useCallback((field, value) => {
    setData((prevState) => ({ ...prevState, [field]: value }));
  }, []); // eslint-disable-line

  const handleHKAChange = (value) => {
    handleChange('HKA', value);
    handleChange('Pre-Op_HKA', (18000 - value * 100) / 100);
  };

  const handleRemovePlanFile = (name) => {
    setPlanFile(planFile.filter((file) => file.name !== name));
  };

  const handleResponse = (res, field) => {
    if (res && res.body) {
      const result = JSON.parse(res.body);

      if (res.statusCode === 500) {
        console.log(result.error);
      } else {
        handleChange(field, result.toFixed(1));
      }
    }
    toggleLoading(false);
    toggleCalculating((prevState) => ({ ...prevState, [field]: false }));
  };

  const calculateRTW = useCallback(
    debounce((ptw, tw) => {
      if (ptw && tw) {
        toggleLoading(true);
        toggleCalculating((prevState) => ({ ...prevState, 'R_%TW': true }));
        api.post(urls.calculation.rtw, { ptw: +ptw, tw: +tw }).then((res) => handleResponse(res, 'R_%TW'));
      }
    }, delay),
    []
  );

  const calculateTMA = useCallback(
    debounce((jla, rtw, twjla) => {
      if (jla && rtw && twjla) {
        toggleLoading(true);
        toggleCalculating((prevState) => ({ ...prevState, T_MA: true }));
        api.post(urls.calculation.tma, { jla, rtw, twjla }).then((res) => handleResponse(res, 'T_MA'));
      }
    }, delay),
    []
  );

  const calculateTWTMA = useCallback(
    debounce((jla, tma, twjla) => {
      if (jla && tma && twjla) {
        toggleLoading(true);
        toggleCalculating((prevState) => ({ ...prevState, TW_T_MA: true }));
        api.post(urls.calculation.twtma, { jla, tma, twjla }).then((res) => handleResponse(res, 'TW_T_MA'));
      }
    }, delay),
    []
  );

  const calculateFMA = useCallback(
    debounce((jlcofh, twjlcofh, rtw) => {
      if (jlcofh && twjlcofh && rtw) {
        toggleLoading(true);
        toggleCalculating((prevState) => ({ ...prevState, F_MA: true }));
        api.post(urls.calculation.fma, { jlcofh, twjlcofh, rtw }).then((res) => handleResponse(res, 'F_MA'));
      }
    }, delay),
    []
  );

  const calculateTWFMA = useCallback(
    debounce((jlcofh, twjlcofh, fma) => {
      if (jlcofh && twjlcofh && fma) {
        toggleLoading(true);
        toggleCalculating((prevState) => ({ ...prevState, TW_F_MA: true }));
        api.post(urls.calculation.twfma, { jlcofh, twjlcofh, fma }).then((res) => handleResponse(res, 'TW_F_MA'));
      }
    }, delay),
    []
  );

  const calculateFujisawa = useCallback(
    debounce((ptwPost, tw, jla, twjla, jlcofh, twjlcofh, preop) => {
      if (ptwPost && tw && jla && twjla && jlcofh && twjlcofh && preop) {
        api
          .post(urls.calculation.fujisawa, {
            ptwPost,
            tw,
            jla,
            twjla,
            jlcofh,
            twjlcofh,
            preop,
            procedureFlow: activeCase.procedureFlow,
          })
          .then((res) => {
            if (res && res.body) {
              const result = JSON.parse(res.body);

              if (res.statusCode === 500) {
                console.log(result.error);
              } else {
                const { dca, rca } = result;
                setDCA(dca ? +dca?.toFixed(1) : 0);
                setRCA(rca ? +rca?.toFixed(1) : 0);
              }
            }
          });
      }
    }, delay),
    []
  );

  const onCheck = (e, key) => {
    setValidation((prevState) => ({ ...prevState, [key]: e.target.checked }));
  };

  const handleSetTrainingData = async (type) => {
    const dataSet = type === alignmentDataTypes.varus ? VARUS_DATASET : VALGUS_DATASET;

    if (
      activeCase.procedureFlow === procedureFlows.DFO_Lateral ||
      activeCase.procedureFlow === procedureFlows.DFO_Medial
    ) {
      delete dataSet.TW_Osteotomy;
      dataSet.FW_Osteotomy = 65;
    }

    toggleLoading(true);

    const RTW = await api
      .post(urls.calculation.rtw, { ptw: dataSet['%TW'], tw: dataSet['TW'] })
      .then((res) => Number(res.body));

    const T_MA = await api
      .post(urls.calculation.tma, { jla: dataSet['JL_A'], rtw: RTW, twjla: dataSet['TW_JL_A'] })
      .then((res) => Number(res.body));

    const TW_T_MA = await api
      .post(urls.calculation.twtma, { jla: dataSet['JL_A'], tma: T_MA, twjla: dataSet['TW_JL_A'] })
      .then((res) => Number(res.body));

    const F_MA = await api
      .post(urls.calculation.fma, {
        jlcofh: dataSet['JL_CoFH'],
        twjlcofh: dataSet['TW_JL_CoFH'],
        rtw: RTW,
      })
      .then((res) => Number(res.body));

    const TW_F_MA = await api
      .post(urls.calculation.twfma, {
        jlcofh: dataSet['JL_CoFH'],
        twjlcofh: dataSet['TW_JL_CoFH'],
        fma: F_MA,
      })
      .then((res) => Number(res.body));

    const alignmentData = {
      ...dataSet,
      'R_%TW': RTW,
      T_MA,
      TW_T_MA,
      F_MA,
      TW_F_MA,
      'Tibial_Width': data['%TW'] || 50,
    };

    const now = moment().format();
    const activity = [{ ...caseConstants.caseNotifications[3].success, date: now, userId }, ...activeCase.activity];

    await updateCase({
      ...activeCase,
      step: 4,
      activity,
      completionDates: { ...activeCase.completionDates, step3: now },
      completedBy: { ...activeCase.completedBy, step3: userId },
      alignmentData: { ...alignmentData, type },
      status: caseConstants.statuses.inProgress,
    }).then(() => {
      toggleLoading(false);
      onClose();
    });
  };

  const handleSubmit = () => {
    if (isDisabled()) {
      return;
    }

    const now = moment().format();
    const activity = [{ ...caseConstants.caseNotifications[3].success, date: now, userId }, ...activeCase.activity];

    Object.keys(data).forEach((key) => {
      const value = data[key];

      data[key] = value === undefined ? 0 : Number(value);
    });

    const files = [...activeCase.files];
    const newFiles = [];

    if (!!planFile[0]) {
      const file = planFile[0];

      if (file) {
        files.unshift({
          id: `${activeCase.id}_${fileTypes.alignmentAnalysis}_${file.name}`,
          name: file.name,
          type: fileTypes.alignmentAnalysis,
          status: fileStatuses.PENDING,
          date: now,
          userId,
        });
        newFiles.push(file);
      }
    }

    updateCase({
        ...activeCase,
        step: 4,
        activity,
        completionDates: { ...activeCase.completionDates, step3: now },
        completedBy: { ...activeCase.completedBy, step3: userId },
        alignmentData: { ...data, type },
        status: caseConstants.statuses.inProgress,
        files
      }, newFiles,
    ).then(() => onClose());
  };

  const handleSave = () => {
    if (isDisabled()) {
      return;
    }

    Object.keys(data).forEach((key) => {
      const value = data[key];
      data[key] = value === undefined ? 0 : Number(value);
    });

    toggleSubmitModal(false);
    simpleUpdateCase({
      ...activeCase,
      step: 3,
      alignmentData: { ...data, type },
      status: caseConstants.statuses.inProgress,
      isValidated: true,
    }).then(() => onClose());
  };

  const isDisabled = () => {
    const dataCopy = Object.assign({}, data);

    delete dataCopy['WBL'];

    let fileUploaded = true;

    if (!planFile[0]) {
      fileUploaded = false;
    }

    if (validationMode) {
      return (
        Object.values(dataCopy).filter((value) => value).length < 19 ||
        Object.values(validation).filter((value) => !!value).length < 14
      ) || !type || !fileUploaded;
    } else {
      return Object.values(dataCopy).filter((value) => value).length < 19 || !type || !fileUploaded;
    }
  };

  const renderForm = (flow) => {
    if (flow === procedureFlows.HTO || flow === procedureFlows.HTO_LATERAL) {
      return (
        <HtoForm
          activeCase={activeCase}
          trainingMode={trainingMode}
          loading={loading}
          handleSetTrainingData={handleSetTrainingData}
          type={type}
          setType={setType}
          data={data}
          handleHKAChange={handleHKAChange}
          validationMode={validationMode}
          validation={validation}
          onCheck={onCheck}
          handleChange={handleChange}
          calculating={calculating}
          calculationData={calculationDataHTO}
          HKA={HKA}
          DCA={DCA}
          RCA={RCA}
          PTS={PTS}
          initialTibialWidth={initialTibialWidth}
          tibialWidth={data['%TW']}
          initialPTS={initialPTS}
          onClose={onClose}
          handleSubmit={() => toggleSubmitModal(true)}
          isDisabled={isDisabled}
          toggleModal={toggleModal}
          planFile={planFile}
          setPlanFile={setPlanFile}
          handleRemovePlanFile={handleRemovePlanFile}
        />
      );
    }

    if (flow === procedureFlows.DFO_Lateral || flow === procedureFlows.DFO_Medial) {
      return (
        <DfoForm
          activeCase={activeCase}
          trainingMode={trainingMode}
          loading={loading}
          handleSetTrainingData={handleSetTrainingData}
          type={type}
          setType={setType}
          data={data}
          handleHKAChange={handleHKAChange}
          validationMode={validationMode}
          validation={validation}
          onCheck={onCheck}
          handleChange={handleChange}
          calculating={calculating}
          calculationData={calculationDataDFO}
          HKA={HKA}
          DCA={DCA}
          RCA={RCA}
          PTS={PTS}
          initialTibialWidth={initialTibialWidth}
          tibialWidth={data['%TW']}
          initialPTS={initialPTS}
          onClose={onClose}
          handleSubmit={() => toggleSubmitModal(true)}
          isDisabled={isDisabled}
          toggleModal={toggleModal}
          planFile={planFile}
          setPlanFile={setPlanFile}
          handleRemovePlanFile={handleRemovePlanFile}
        />
      );
    }
  };

  return (
    <div className="case-step-container">
      {renderForm(activeCase.procedureFlow)}

      {!activeCase.procedureFlow && <NoProcedureFlow procedureName={activeCase.procedureName} />}

      <AlertModal
        open={open}
        onClose={() => {
          toggleModal(false);
          toggleValidation(false);
        }}
        onSubmit={() => {
          toggleModal(false);
          toggleValidation(true);
        }}
        submitText="Validate"
        cancelText="Back"
        title="Please verify Inputs"
        text="Please validate each input by checking the box next to each value before submitting data"
      />

      <ConfirmationModal
        open={submitModalOpen}
        onClose={() => toggleSubmitModal(false)}
        onSubmit={handleSubmit}
        onSave={handleSave}
      />
    </div>
  );
};
