import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import WarningIcon from '@material-ui/icons/Warning';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import HardwareList from '../../HardwareList';
import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';

import Button from '../../../../../shared/Button';
import Dropzone from '../../../../../shared/dropzone/Dropzone';

export const CmfExtendedStep5 = (props) => {
  const {
    classes,
    onClose,
    activeCase,
    updateCase,
    products,
    designReport,
    designReportRejected,
    manufacturingFiles,
    manufacturingFilesRejected,
    caseMaxilla,
    fileStatuses,
    fileTypes,
    statuses,
    caseNotifications,
    userId,
  } = props;

  const [preop, setPreop] = useState([]);
  const [manufacturing, setManufacturingFiles] = useState([]);
  const [maxillaList, setMaxillaList] = useState(caseMaxilla ? caseMaxilla : []);

  const requiredDate = activeCase?.date ? moment(activeCase?.date).subtract(7, 'd') : moment();
  const [date, setDate] = useState(requiredDate);

  const handleRemovePreop = (name) => {
    setPreop(preop.filter((file) => file.name !== name));
  };

  const handleRemoveManufacturing = (name) => {
    setManufacturingFiles(manufacturing.filter((file) => file.name !== name));
  };

  const handleAddMaxilla = (product) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setMaxillaList(list);
    } else {
      setMaxillaList([...maxillaList, product]);
    }
  };

  const handleRemoveMaxilla = (id) => {
    setMaxillaList(maxillaList.filter((item) => item.id !== id));
  };

  const handleAmountChange = (id, amount) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setMaxillaList(list);
  };

  const handleCategoryChange = (id, category) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setMaxillaList(list);
  };

  const handleChange = (id, product) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setMaxillaList(list);
  };

  const handleSubmit = () => {
    const now = moment().format();

    const activity = [
      { ...caseNotifications[5].success, date: now, userId },
      { ...caseNotifications[5].file, date: now, userId },
      ...activeCase.activity,
    ];

    const files = [...activeCase.files];
    let newFiles = [];
    const preopFile = preop[0];

    if (preopFile) {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.designReport}_${preopFile.name}`,
        name: preopFile.name,
        type: fileTypes.designReport,
        status: fileStatuses.PENDING,
        date: now,
        userId,
      });
      newFiles.push(preopFile);
    }

    manufacturing?.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.manufacturing}_${file.name}`,
        name: file.name,
        type: fileTypes.manufacturing,
        status: fileStatuses.PENDING,
        date: now,
        userId,
      });
      newFiles.push(file);
    });

    const approvedPreop = files.find((file) => file.type === fileTypes.designReport && file.status === fileStatuses.APPROVED);

    updateCase(
      {
        ...activeCase,
        step: approvedPreop ? 7 : 6,
        activity,
        components: maxillaList.map((item) => ({
          product: item.product,
          amount: item.amount,
          category: item.category,
        })),
        files,
        completionDates: { ...activeCase.completionDates, step5: now },
        completedBy: { ...activeCase.completedBy, step5: userId },
        status: statuses.inProgress,
        notes: {
          ...activeCase.notes,
        },
        requiredDate: date.format(),
      },
      newFiles
    ).then(() => onClose());
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">5</div>
          </div>
          <div className="modal-item-content">
            <div className="m-b-lg">Upload Design Report</div>
            {activeCase.access ? (
              <div>
                <div className="m-t-lg m-b-md">
                  <div className="secondary font-size-sm m-b-sm">ATTACH DESIGN REPORT (.pdf)</div>
                  {designReportRejected && (
                    <div className="alert warning m-b-md">
                      <div className="d-flex align-center">
                        <WarningIcon color="primary" />
                        <div>Design report has been rejected</div>
                      </div>
                      {activeCase.notes && activeCase.notes.step6 && (
                        <div className="m-t-md">Note: {activeCase.notes.step6}</div>
                      )}
                    </div>
                  )}
                  <Dropzone
                    files={preop}
                    onUpload={setPreop}
                    type="pdf"
                    icon="preop"
                    multiple={false}
                    onRemove={handleRemovePreop}
                  />
                </div>
                <div className="m-t-lg m-b-md">
                  <div className="secondary font-size-sm m-b-sm">ATTACH MANUFACTURING FILES (.zip)</div>
                  {manufacturingFilesRejected && (
                    <div className="alert warning m-b-md">
                      <div className="d-flex align-center">
                        <WarningIcon color="primary" />
                        <div>Manufacturing files have been rejected</div>
                      </div>
                      {activeCase.notes && activeCase.notes.step7 && (
                        <div className="m-t-md">Note: {activeCase.notes.step7}</div>
                      )}
                    </div>
                  )}
                  <Dropzone
                    files={manufacturing}
                    onUpload={setManufacturingFiles}
                    type="archive"
                    icon="preop"
                    multiple
                    onRemove={handleRemoveManufacturing}
                  />
                </div>
                <div className="m-t-lg">
                  <HardwareList
                    classes={classes}
                    hardwareList={maxillaList}
                    products={products}
                    onAdd={handleAddMaxilla}
                    onRemove={handleRemoveMaxilla}
                    onAmountChange={handleAmountChange}
                    onCategoryChange={handleCategoryChange}
                    onChange={handleChange}
                    title="Components"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>

                <div className="hardware-list-container">
                  <div className="hardware-list-header">
                    <div className="secondary font-size-sm">REQUIRED DATE</div>
                  </div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      id="date-picker"
                      value={date}
                      onChange={(date) => setDate(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={5} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button
          text="Submit"
          color="primary"
          onClick={handleSubmit}
          disabled={!activeCase.access || (!designReport && !preop.length) || (!manufacturingFiles?.length && !manufacturing?.length) || !maxillaList.length}
        />
      </div>
    </div>
  );
};
