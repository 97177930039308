import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core';

import NoAccess from '../../NoAccess';
import Button from '../../../../../shared/Button';
import CaseAccordion from '../../CaseAccordion';

import preopIcon from '../../../../../../assets/preop_icon.svg';
import calendarIcon from '../../../../../../assets/calendar_icon.svg';

import { updateCase } from '../../../../../../actions/casesActions';

import { caseConstants } from '../../../../../../constants';
import { roleNames } from '../../../../../../constants/userRoles';
import HardwareList from '../../HardwareList';

const { fileStatuses, caseACLNotifications, statuses, stages } = caseConstants;
const { GLOBAL_ADMIN, COUNTRY_ADMIN } = roleNames;

const AclStep4 = ({ classes, onClose, activeCase, preop, updateCase, userRole, products, userId }) => {
  const {
    patientInfo,
    date,
    hospital,
    procedureName,
    hardware: caseHardware,
    manufacturing: caseManufacturing,
  } = activeCase;

  const [status, setStatus] = useState(fileStatuses.APPROVED);
  const [stage, setStage] = useState('');
  const [notes, setNotes] = useState('');
  const [hardwareList, setHardwareList] = useState(caseHardware ? caseHardware : []);
  const [manufacturingList, setManufacturingList] = useState(caseManufacturing ? caseManufacturing : []);

  const handleAddHardware = (product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setHardwareList(list);
    } else {
      setHardwareList([...hardwareList, product]);
    }
  };

  const handleRemoveHardware = (id) => {
    setHardwareList(hardwareList.filter((item) => item.id !== id));
  };

  const handleAmountChange = (id, amount) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setHardwareList(list);
  };

  const handleCategoryChange = (id, category) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setHardwareList(list);
  };

  const handleChange = (id, product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setHardwareList(list);
  };

  const handleAddManufacturing = (product) => {
    const list = [...manufacturingList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setManufacturingList(list);
    } else {
      setManufacturingList([...manufacturingList, product]);
    }
  };

  const handleRemoveManufacturing = (id) => {
    setManufacturingList(manufacturingList.filter((item) => item.id !== id));
  };

  const handleAmountManufacturingChange = (id, amount) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.amount = amount;
    setManufacturingList(list);
  };

  const handleCategoryManufacturingChange = (id, category) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.category = category;
    setManufacturingList(list);
  };

  const handleManufacturingChange = (id, product) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.product = product;
    setManufacturingList(list);
  };

  const handleSubmit = () => {
    const now = moment().format();
    const activity = [...activeCase.activity];
    const files = [...activeCase.files];
    const preopFile = files.find((file) => file.id === preop.id);

    if (!preopFile) {
      return;
    }

    let rejected = false;

    if (status === fileStatuses.REJECTED) {
      activity.unshift({ ...caseACLNotifications[4].reject, date: now, userId });
      rejected = true;
    } else {
      activity.unshift({ ...caseACLNotifications[4].success, date: now, userId });
      rejected = false;
    }

    preopFile.status = status;

    const data = {
      ...activeCase,
      files,
      step: rejected ? 3 : 5,
      activity,
      completionDates: { ...activeCase.completionDates, step4: now },
      completedBy: { ...activeCase.completedBy, step4: userId },
      notes: { ...activeCase.notes, step4: notes },
      status: rejected ? statuses.rejected : statuses.inProgress,
      stage,
    };

    if (stage === stages.singleStage) {
      data.hardware = hardwareList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category,
      }));
    }

    if (stage === stages.twoStage) {
      data.manufacturing = manufacturingList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category,
      }));
    }

    updateCase(data, rejected).then(() => {
      onClose();
    });
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">4</div>
          </div>
          <div className="modal-item-content">
            <div className="m-b-lg">Approve Pre-Op Plan</div>
            {activeCase.access ? (
              <Fragment>
                {!!preop && (
                  <div className="modal-item-block margin-0">
                    <div className="secondary font-size-sm border-bottom p-b-md m-b-md">
                      Please review the proposed Pre-Op plan below and approve or reject with supporting notes
                    </div>
                    <div className="d-flex space-between">
                      <div>
                        {patientInfo.firstName} {patientInfo.lastName}
                      </div>
                      <div className="d-flex">
                        <img src={calendarIcon} alt="calendar-icon" height={15} className="m-r-sm" />
                        {moment(date).format('DD/MM/YYYY')}
                      </div>
                    </div>
                    <div className="d-flex space-between m-b-lg">
                      <div>{hospital}</div>
                      <div className={classes.primary}>{procedureName}</div>
                    </div>
                    <div className="block-header">Attached pre-op plan</div>
                    <div className="preop-image-container d-flex flex-center">
                      <a
                        href={preop.downloadLink}
                        target="_blank"
                        className={`${classes.primary} text-center`}
                        rel="noopener noreferrer"
                        download
                      >
                        <img src="Pre-Op_Front_Page_drop_ACL_planning.png" alt="preop" style={{ maxHeight: 300 }} width="100%" />
                        <div className=" text-center pointer">Click to view</div>
                      </a>
                    </div>
                    <div className="d-flex p-t-md p-b-md flex-center">
                      <img src={preopIcon} alt="preop-icon" height={20} className="m-r-sm" />
                      <span>{preop.name}</span>
                    </div>
                    <div className="radio-group-container d-flex flex-center">
                      <RadioGroup
                        aria-label="preop-radio"
                        name="preop-radio"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <FormControlLabel
                          value={fileStatuses.APPROVED}
                          control={<Radio color="primary" />}
                          label="Approve"
                        />
                        <FormControlLabel
                          value={fileStatuses.REJECTED}
                          control={<Radio color="primary" />}
                          label="Reject"
                        />
                      </RadioGroup>
                    </div>
                    {status === fileStatuses.REJECTED && (
                      <TextField
                        id="notes"
                        type="text"
                        fullWidth
                        multiline
                        label="Notes"
                        placeholder="Add reason for reject here"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    )}
                    <div className="m-t-lg">
                      <div className="block-header">Select Procedure Pathway</div>
                      <p className="secondary font-size-sm p-b-md m-b-md">
                        Please select whether this case will be a single stage or a two stage procedure
                      </p>
                      <div className="radio-group-container d-flex flex-center">
                        <RadioGroup
                          style={{ display: 'block' }}
                          name="preop-radio"
                          value={stage}
                          onChange={(e) => setStage(e.target.value)}
                        >
                          <FormControlLabel
                            value={stages.singleStage}
                            control={<Radio color="primary" />}
                            label="Single Stage"
                          />
                          <FormControlLabel
                            value={stages.twoStage}
                            control={<Radio color="primary" />}
                            label="Two Stage"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    {stage === stages.singleStage && (
                      <div className="m-t-lg">
                        <HardwareList
                          classes={classes}
                          hardwareList={hardwareList}
                          products={products}
                          onAdd={handleAddHardware}
                          onRemove={handleRemoveHardware}
                          onAmountChange={handleAmountChange}
                          onCategoryChange={handleCategoryChange}
                          onChange={handleChange}
                          title="1 Stage Hardware"
                          procedureFlow={activeCase.procedureFlow}
                        />
                      </div>
                    )}
                    {stage === stages.twoStage && (
                      <div className="m-t-lg">
                        <HardwareList
                          classes={classes}
                          hardwareList={manufacturingList}
                          products={products}
                          onAdd={handleAddManufacturing}
                          onRemove={handleRemoveManufacturing}
                          onAmountChange={handleAmountManufacturingChange}
                          onCategoryChange={handleCategoryManufacturingChange}
                          onChange={handleManufacturingChange}
                          title="2 Stage Hardware"
                          procedureFlow={activeCase.procedureFlow}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Fragment>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        {(userRole === GLOBAL_ADMIN || userRole === COUNTRY_ADMIN || !activeCase.access) && <CaseAccordion currentStep={4} />}
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button
          text="Submit"
          color="primary"
          onClick={handleSubmit}
          disabled={!activeCase.access || (status === fileStatuses.REJECTED && !notes.length)}
        />
      </div>
    </div>
  );
};

AclStep4.propTypes = {
  activeCase: PropTypes.object,
  preop: PropTypes.object,
  onClose: PropTypes.func,
  updateCase: PropTypes.func,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];

  return {
    activeCase,
    preop: state.cases.activeCase.files.find((file) => file.type === 'preop'),
    users: state.users.list,
    userRole: state.user.currentUser.role,
    userId: state.user.currentUser.uid,
    products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, rejected) => dispatch(updateCase(newCase, 4, null, rejected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AclStep4);
