import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { OsteotomyStep5 } from './osteotomy-step5/OsteotomyStep5';
import { AclStep5 } from './acl-step5/AclStep5';
import { CmfStep5 } from './cmf-step5/CmfStep5';
import { CmfExtendedStep5 } from './cmf-extended-step-5/CmfExtendedStep5';

import { updateCase } from '../../../../../actions/casesActions';

import { caseConstants } from '../../../../../constants';

const {
  fileStatuses,
  fileTypes,
  statuses,
  procedureFlows,
  caseNotifications,
  caseACLNotifications,
  caseCMFNotifications,
  caseCMFExtendedNotifications,
} = caseConstants;

const Step5 = (props) => {
  const {
    classes,
    onClose,
    activeCase,
    updateCase,
    products,
    preopPlan,
    preopRejected,
    caseHardware,
    caseManufacturing,
    userId,
    caseComponents,
    designReport,
    designReportRejected,
    manufacturingFiles,
    manufacturingFilesRejected,
  } = props;

  const renderStep5 = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        <OsteotomyStep5
          classes={classes}
          onClose={onClose}
          activeCase={activeCase}
          updateCase={updateCase}
          products={products}
          preopPlan={preopPlan}
          preopRejected={preopRejected}
          manufacturingFiles={manufacturingFiles}
          manufacturingFilesRejected={manufacturingFilesRejected}
          caseHardware={caseHardware}
          caseManufacturing={caseManufacturing}
          fileStatuses={fileStatuses}
          fileTypes={fileTypes}
          statuses={statuses}
          procedureFlows={procedureFlows}
          caseNotifications={caseNotifications}
          userId={userId}
        />
      );
    }

    if (flow === procedureFlows.ACL) {
      return (
        <AclStep5
          classes={classes}
          onClose={onClose}
          activeCase={activeCase}
          updateCase={updateCase}
          products={products}
          caseNotifications={caseACLNotifications}
          fileTypes={fileTypes}
          statuses={statuses}
          userId={userId}
        />
      );
    }

    if (flow === procedureFlows.CMF) {
      return (
        <CmfStep5
          classes={classes}
          onClose={onClose}
          activeCase={activeCase}
          updateCase={updateCase}
          products={products}
          caseNotifications={caseCMFNotifications}
          fileTypes={fileTypes}
          statuses={statuses}
          userId={userId}
        />
      );
    }

    if ([procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return (
        <CmfExtendedStep5
          classes={classes}
          onClose={onClose}
          activeCase={activeCase}
          updateCase={updateCase}
          products={products}
          designReport={designReport}
          designReportRejected={designReportRejected}
          manufacturingFiles={manufacturingFiles}
          manufacturingFilesRejected={manufacturingFilesRejected}
          caseMaxilla={caseComponents}
          fileStatuses={fileStatuses}
          fileTypes={fileTypes}
          statuses={statuses}
          caseNotifications={caseCMFExtendedNotifications}
          userId={userId}
        />
      );
    }
  };

  return renderStep5(activeCase.procedureFlow);
};

Step5.propTypes = {
  activeCase: PropTypes.object,
  onClose: PropTypes.func,
  updateCase: PropTypes.func,
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];
  const preopPlan = state.cases.activeCase.files.find((file) => file.type === fileTypes.preop);
  const designReport = state.cases.activeCase.files.find((file) => file.type === fileTypes.designReport);
  const manufacturingFiles = state.cases.activeCase.files.filter((file) => file.type === fileTypes.manufacturing);
  const caseHardware = state.cases.activeCase.hardware;
  const caseManufacturing = state.cases.activeCase.manufacturing;

  const caseMaxilla = state.cases.activeCase?.maxilla || [];
  const caseLeftMandible = state.cases.activeCase?.leftMandible || [];
  const caseRightMandible = state.cases.activeCase?.rightMandible || [];
  const caseGenioplasty = state.cases.activeCase?.genioplasty || [];
  const caseComponents = state.cases.activeCase?.components || [];

  return {
    activeCase,
    products,
    preopPlan: preopPlan && preopPlan.status !== fileStatuses.REJECTED ? preopPlan : null,
    designReport: designReport && designReport.status !== fileStatuses.REJECTED ? designReport : null,
    preopRejected: preopPlan && preopPlan.status === fileStatuses.REJECTED,
    designReportRejected: designReport && designReport.status === fileStatuses.REJECTED,
    manufacturingFiles: manufacturingFiles && manufacturingFiles.filter((file) => file.status !== fileStatuses.REJECTED),
    manufacturingFilesRejected: manufacturingFiles && !!manufacturingFiles.filter((file) => file.status === fileStatuses.REJECTED).length,
    caseHardware,
    caseManufacturing,
    caseMaxilla,
    caseLeftMandible,
    caseRightMandible,
    caseGenioplasty,
    caseComponents,
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, newFiles, rejected) => dispatch(updateCase(newCase, 5, newFiles, rejected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step5);
