import React from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import Hidden from '@material-ui/core/Hidden';

import Modal from '../../modals/Modal';
import Button from '../../shared/Button';

import './case-approve-date-modal.scss';

const CaseApproveDateModal = (props) => {
  const { open, onClose, selectedCase, onApprove, onReject, loading } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Approve New Surgery Date"
      subtitle={selectedCase?.formattedId || selectedCase?.id}
      size="md"
      autoSize
    >
      <div className="approve_surgery_date_modal">
        <div className="p-lg width-100">
          <div className="secondary">Current Surgery Date</div>
          {selectedCase?.date ? (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD/MM/YYYY"
                fullWidth
                id="date-picker-disabled"
                value={selectedCase?.date}
                disabled
              />
            </MuiPickersUtilsProvider>
          ) : (
            <TextField
              style={{ width: '100%' }}
              id="date"
              type="text"
              disabled
              value='TBC'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <EventIcon style={{ fill: '#bdbdbd', marginRight: '4px' }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div>
        <div className="p-lg width-100">
          <div className="secondary">Requested New Surgery Date</div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              fullWidth
              id="date-picker-disabled"
              value={moment(selectedCase?.requestedSurgeryDate?.newDate)}
              disabled
            />
          </MuiPickersUtilsProvider>
        </div>
        {!!selectedCase?.requestedSurgeryDate?.newHospital && selectedCase?.requestedSurgeryDate?.newHospital?.id !== selectedCase?.hospitalUniq?.id && (
          <>
            <div className="p-lg width-100">
              <div className="secondary">Current Hospital</div>
              <TextField
                style={{ width: '100%' }}
                id="hospital"
                type="text"
                disabled
                value={selectedCase?.hospital}
              />
            </div>
            <div className="p-lg width-100">
              <div className="secondary">Requested New Hospital</div>
              <TextField
                style={{ width: '100%' }}
                id="hospital"
                type="text"
                disabled
                value={selectedCase?.requestedSurgeryDate?.newHospital?.name}
              />
            </div>
          </>
        )}

        <Hidden smDown implementation="css">
          <div className="p-lg width-100 d-flex space-between">
            <div className='m-r-md'>
              <Button
                text="Reject"
                type="secondary"
                onClick={onReject}
                disabled={loading}
                loading={loading}
              />
            </div>
            <div className='m-l-md'>
              <Button
                text="Approve"
                color="primary"
                onClick={onApprove}
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div className="p-md width-100 d-flex direction-column align-center">
            <div className='m-b-lg'>
              <Button
                text="Approve"
                color="primary"
                onClick={onApprove}
                disabled={loading}
                loading={loading}
              />
            </div>
            <div className=''>
              <Button
                text="Reject"
                type="secondary"
                onClick={onReject}
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>
        </Hidden>
      </div>
    </Modal>
  );
};

export default CaseApproveDateModal;
