import React from 'react';
import { connect } from 'react-redux';

import { updateCase } from '../../../../../actions/casesActions';

import { caseConstants } from '../../../../../constants';

import OsteotomyStep8 from './osteotomy-step8/OsteotomyStep8'
import CmfExtendedStep8 from './cmf-extended-step8/CmfExtendedStep8'

const { procedureFlows } = caseConstants;

const Step8 = ({ classes, onClose, activeCase, userId, updateCase }) => {
  const renderStep8 = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        <OsteotomyStep8
          classes={classes}
          activeCase={activeCase}
          onClose={onClose}
        />
      );
    }

    if ([procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return (
        <CmfExtendedStep8
          onClose={onClose}
          activeCase={activeCase}
          userId={userId}
          updateCase={updateCase}
        />
      );
    }
  };

  return renderStep8(activeCase.procedureFlow);
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, newFiles, rejected) => dispatch(updateCase(newCase, 8, newFiles, rejected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step8);
