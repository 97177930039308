import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { orderBy } from 'lodash';

import { makeStyles, IconButton} from '@material-ui/core';
import UploadIcon from '@material-ui/icons/Loop';
import BackIcon from '@material-ui/icons/ArrowBackOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import documentIcon from '../../../assets/ps_document_icon.svg';
import deleteIcon from '../../../assets/ps_delete_icon.svg';
import downloadIcon from '../../../assets/ps_cloud_download_icon.svg';

import Dropzone from '../../shared/dropzone/Dropzone';
import AlertModal from '../AlertModal';

import {
  changePreopFile,
  setActiveCase,
  setActiveTab,
  simpleUpdateCase,
  deleteCaseFile,
  changeAlignmentAnalysisFile,
} from '../../../actions/casesActions';

import { getFileLabel, getFileIcon } from '../../../util/cases';

import { caseConstants, userRoles } from '../../../constants';
import { roleNames } from '../../../constants/userRoles';

const { fileRestrictions, fileTypes, procedureFlows } = caseConstants;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  container: {
    width: '100%',
  },
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const fileTypeOptions = Object.values(fileTypes).map((type) => ({ type, label: getFileLabel(type) }));

const CaseFiles = ({
  activeCase,
  role,
  changePreopFile,
  changeAlignmentAnalysisFile,
  onClose,
  goToFiles,
  onBackClick,
  users = [],
  userId,
  updateCaseFiles,
  setActiveCase,
  deleteCaseFile
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { files } = activeCase;

  const [tabIndex, setTabIndex] = useState(2);
  const [fileType, setFileType] = useState('');
  const [uploading, setUploading] = useState(false);

  const [deleteModalOpen, toggleDeleteModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload = async (fileType, { target }) => {
    const file = target.files[0];
    if (fileType === fileTypes.alignmentAnalysis) {
      await changeAlignmentAnalysisFile(activeCase, file);
    } else {
      await changePreopFile(activeCase, file);
    }
    onClose();
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
    dispatch(setActiveTab(newIndex));
  };

  const onUpload = async (newFiles) => {
    if (newFiles && newFiles[0]) {
      try {
        setUploading(true);
        const file = newFiles[0];
        const now = moment().format();
        const files = [...activeCase.files];
        files.unshift({
          id: `${activeCase.id}_${fileType}_${file.name}`,
          name: file.name,
          type: fileType,
          date: now,
          userId,
        });
        await updateCaseFiles({ ...activeCase, files }, [file]);
        await setActiveCase({ ...activeCase, files });
      } catch (err) {
        console.error(err);
      } finally {
        setUploading(false);
      }
    }
  };

  // const tabs = [{ label: 'Process' }, { label: '', disabled: true }, { label: 'Files' }];

  const getUserName = (uid) => {
    const user = users?.find((u) => u.uid === uid);
    return user ? ` | ${user.firstName} ${user.lastName}` : '';
  };

  const onDeleteClick = (file) => {
    setSelectedFile(file);
    toggleDeleteModal(true);
  };

  const onDelete = async () => {
    try {
      if (selectedFile) {
        await deleteCaseFile(activeCase, selectedFile.id);
        toggleDeleteModal(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const downloadEnabled = (file) => (
    [procedureFlows.CMF_EXTENDED, procedureFlows.CMF, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow) && role === roleNames.SURGEON ? (
      file.type === fileTypes.designReport || file.type === fileTypes.planReport
    ) : (
      fileRestrictions[file.type] && fileRestrictions[file.type].includes(role)
    )
  );

  return (
    <div className={classes.container}>
      {/*{activeCase.step === 4 && ([procedureFlows.DFO_Lateral, procedureFlows.DFO_Medial, procedureFlows.HTO, procedureFlows.CUSTOM].includes(activeCase.procedureFlow)) && (*/}
      {/*  <AppBar position="static" color="default">*/}
      {/*    <Tabs*/}
      {/*      value={tabIndex}*/}
      {/*      onChange={handleTabChange}*/}
      {/*      indicatorColor="primary"*/}
      {/*      textColor="primary"*/}
      {/*      variant="fullWidth"*/}
      {/*    >*/}
      {/*      {tabs.map((tab, index) => (*/}
      {/*        <Tab*/}
      {/*          key={index}*/}
      {/*          label={typeof tab === 'object' ? tab.label : tab}*/}
      {/*          disabled={tab.disabled}*/}
      {/*          {...a11yProps(index)}*/}
      {/*        />*/}
      {/*      ))}*/}
      {/*    </Tabs>*/}
      {/*  </AppBar>*/}
      {/*)}*/}

      <div className="case-files-container">
        {activeCase && activeCase.files && activeCase.files.length ? (
          <div className="case-data-list">
            {files.map((item) => (
              <div key={`${item.name}-${item.type}-${item.date}`} className="data-list-item">
                <div className="width-100">
                  <div className='d-flex'>
                    <div className="d-flex align-center icon-file-container flex-1">
                      <img src={getFileIcon(item.type)} alt="file-icon" style={{ height: '20px'}} />
                      <div className="font-bold m-l-md">{getFileLabel(item.type)}</div>
                    </div>
                    {item.status && (
                      <div className={`status-indicator status-indicator-file ${item.status}`}>{item.status}</div>
                    )}
                  </div>
                  <div className='d-flex w-100 space-between m-t-sm'>
                    <div className="">
                      <div className="list-item-title">{item.name}</div>
                      <div className="secondary font-size-sm m-t-sm">
                        {`${moment(item.date).format('MMM Do YY')} | ${moment(item.date).format('HH:mm')}${getUserName(item.userId)}`}
                      </div>
                    </div>
                    <div className='d-flex'>
                      {(item.type === fileTypes.preop || item.type === fileTypes.alignmentAnalysis) && (role === roleNames.GLOBAL_ADMIN || role === roleNames.COUNTRY_ADMIN) && (
                        <div className="m-r-lg">
                          <input
                            className={classes.input}
                            id="icon-button-file"
                            type="file"
                            onChange={(e) => handleUpload(item.type, e)}
                          />
                          <label htmlFor="icon-button-file">
                            <IconButton color="secondary" aria-label="upload pdf" component="span">
                              <UploadIcon />
                            </IconButton>
                          </label>
                        </div>
                      )}
                      {downloadEnabled(item) && (
                        <a href={item.downloadLink} target="_blank" rel="noopener noreferrer" download>
                          <div style={{ padding: '4px', cursor:'pointer' }}>
                            <img src={downloadIcon} alt="file-icon" style={{ height: '24px'}} />
                          </div>
                        </a>
                      )}
                      {(role === userRoles.GLOBAL_ADMIN.name || role === userRoles.COUNTRY_ADMIN.name) && (
                        <div onClick={() => onDeleteClick(item)} style={{ padding: '4px', marginLeft: '4px', cursor: 'pointer' }}>
                          <img src={deleteIcon} alt="file-icon" style={{ height: '24px'}} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="empty-list m-t-lg">
            <img src={documentIcon} alt="scans-icon" />
            No Files
          </div>
        )}
      </div>

      <div className='p-lg'>
          <div className='m-b-lg d-flex' style={{ width: '50%' }}>
            <span className='font-bold m-r-lg'>File type:</span>
            <Select
              id="file-type-select"
              className="file-type-select"
              onChange={(e) => setFileType(e.target.value)}
              placeholder="File type"
              style={{ flex: 1 }}
            >
              {orderBy(fileTypeOptions, 'label').map((file) => (
                <MenuItem key={file.type} value={file.type}>
                  {file.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Dropzone
            files={[]}
            onUpload={onUpload}
            icon="x-ray"
            loading={uploading}
            multiple={false}
            disabled={!fileType}
            type={fileType === fileTypes.designSpecificationList ? 'pdf' : undefined}
          />
        </div>

      <div className='pointer m-t-lg m-l-md p-md d-flex primary p-b-lg' onClick={onBackClick}
           style={{ width: 'fit-content' }}>
        <BackIcon />
        <span className='m-l-md'>Back to the case process</span>
      </div>

      <AlertModal
        open={deleteModalOpen}
        onClose={() => {
          toggleDeleteModal(false);
          setSelectedFile(null);
        }}
        onSubmit={onDelete}
        title="Delete File"
        text="You are about to delete this file. This process cannot be undone. Are you sure?"
        closeText="Cancel"
        submitText="Delete"
      />
    </div>
  );
};

CaseFiles.propTypes = {
  activeCase: PropTypes.object,
  role: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    role: state.user.currentUser.role,
    userId: state.user.currentUser.uid,
    users: state.users?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePreopFile: (activeCase, file) => dispatch(changePreopFile(activeCase, file)),
    changeAlignmentAnalysisFile: (activeCase, file) => dispatch(changeAlignmentAnalysisFile(activeCase, file)),
    onBackClick: () => dispatch(setActiveTab(0)),
    updateCaseFiles: (currCase, updateCaseFiles) => dispatch(simpleUpdateCase(currCase, updateCaseFiles)),
    setActiveCase: (activeCase) => dispatch(setActiveCase(activeCase)),
    deleteCaseFile: (activeCase, fileId) => dispatch(deleteCaseFile(activeCase, fileId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseFiles);
