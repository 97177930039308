import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core';

import NoAccess from '../../NoAccess';
import Button from '../../../../../shared/Button';
import CaseAccordion from '../../CaseAccordion';

import preopIcon from '../../../../../../assets/preop_icon.svg';
import calendarIcon from '../../../../../../assets/calendar_icon.svg';

import { updateCase } from '../../../../../../actions/casesActions';

import { caseConstants } from '../../../../../../constants';
import { roleNames } from '../../../../../../constants/userRoles';
import { fileTypes } from '../../../../../../constants/cases';

const { fileStatuses, caseCMFExtendedNotifications, statuses, procedureFlows } = caseConstants;
const { GLOBAL_ADMIN, COUNTRY_ADMIN } = roleNames;

const getImageSrc = (procedureFlow) => {
  if (procedureFlow === procedureFlows.ONCOL) {
    return "Pre-Op_Front_Page_drop_ONCOL_surgical.png";
  }
  if (procedureFlow === procedureFlows.CUSTOM_EXTENDED) {
    return "Pre-Op_Front_Page_drop_Custom_surgical.png";
  }
  if (procedureFlow === procedureFlows.DOUBLE_LEVEL_OSTEOTOMY) {
    return "Pre-Op_Front_Page_drop_Double_level_surgical.png";
  }
  return "Pre-Op_Front_Page_drop_CMF_surgical.png";
};

const CmfExtendedStep6 = ({ classes, onClose, activeCase, preop, updateCase, userRole, userId }) => {
  const {
    patientInfo,
    date,
    hospital,
    procedureName,
  } = activeCase;

  const [status, setStatus] = useState(fileStatuses.APPROVED);
  const [notes, setNotes] = useState('');

  const handleSubmit = () => {
    const now = moment().format();
    const activity = [...activeCase.activity];
    const files = [...activeCase.files];

    const designReportFile = files.find((file) => file.type === fileTypes.designReport);
    const manufacturingFiles = files.filter((file) => file.type === fileTypes.manufacturing);

    if (!designReportFile) {
      return;
    }

    let rejected = false;

    if (status === fileStatuses.REJECTED) {
      activity.unshift({ ...caseCMFExtendedNotifications[6].reject, date: now, userId });
      rejected = true;
    } else {
      activity.unshift({ ...caseCMFExtendedNotifications[6].success, date: now, userId });
      rejected = false;
    }

    designReportFile.status = status;

    if (rejected) {
      manufacturingFiles.forEach((file) => {
        file.status = fileStatuses.REJECTED;
      });
    }

    const data = {
      ...activeCase,
      files,
      step: rejected ? 5 : 7,
      activity,
      completionDates: { ...activeCase.completionDates, step6: now },
      completedBy: { ...activeCase.completedBy, step6: userId },
      notes: { ...activeCase.notes, step6: notes },
      status: rejected ? statuses.rejected : statuses.inProgress,
    };

    updateCase(data, rejected).then(() => {
      onClose();
    });
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">6</div>
          </div>
          <div className="modal-item-content">
            <div className="m-b-lg">Design Approval</div>
            {activeCase.access ? (
              <Fragment>
                {!!preop && (
                  <div className="modal-item-block margin-0">
                    <div className="secondary font-size-sm border-bottom p-b-md m-b-md">
                      Please review the proposed design report below and approve or reject with supporting notes.
                    </div>
                    <div className="d-flex space-between">
                      <div>
                        {patientInfo.firstName} {patientInfo.lastName}
                      </div>
                      <div className="d-flex">
                        <img src={calendarIcon} alt="calendar-icon" height={15} className="m-r-sm" />
                        {date ? moment(date).format('DD/MM/YYYY') : 'TBC'}
                      </div>
                    </div>
                    <div className="d-flex space-between m-b-lg">
                      <div>{hospital}</div>
                      <div className={classes.primary}>{procedureName}</div>
                    </div>
                    <div className="block-header">Attached design report</div>
                    <div className="preop-image-container d-flex flex-center">
                      <a
                        href={preop.downloadLink}
                        target="_blank"
                        className={`${classes.primary} text-center`}
                        rel="noopener noreferrer"
                        download
                      >
                        <img src={getImageSrc(activeCase.procedureFlow)} alt="preop" style={{ maxHeight: 300 }} width="100%" />
                        <div className=" text-center pointer">Click to view</div>
                      </a>
                    </div>
                    <div className="d-flex p-t-md p-b-md flex-center">
                      <img src={preopIcon} alt="preop-icon" height={20} className="m-r-sm" />
                      <span>{preop.name}</span>
                    </div>
                    <div className="radio-group-container d-flex flex-center">
                      <RadioGroup
                        aria-label="preop-radio"
                        name="preop-radio"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <FormControlLabel
                          value={fileStatuses.APPROVED}
                          control={<Radio color="primary" />}
                          label="Approve"
                        />
                        <FormControlLabel
                          value={fileStatuses.REJECTED}
                          control={<Radio color="primary" />}
                          label="Reject"
                        />
                      </RadioGroup>
                    </div>
                    {status === fileStatuses.REJECTED && (
                      <TextField
                        id="notes"
                        type="text"
                        fullWidth
                        multiline
                        label="Notes"
                        placeholder="Add reason for reject here"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    )}
                  </div>
                )}
              </Fragment>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        {(userRole === GLOBAL_ADMIN || userRole === COUNTRY_ADMIN) && <CaseAccordion currentStep={6} />}
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button
          text="Submit"
          color="primary"
          onClick={handleSubmit}
          disabled={!activeCase.access || (status === fileStatuses.REJECTED && !notes.length)}
        />
      </div>
    </div>
  );
};

CmfExtendedStep6.propTypes = {
  activeCase: PropTypes.object,
  preop: PropTypes.object,
  onClose: PropTypes.func,
  updateCase: PropTypes.func,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];

  return {
    activeCase,
    preop: state.cases.activeCase.files.find((file) => file.type === fileTypes.designReport),
    users: state.users.list,
    userRole: state.user.currentUser.role,
    products,
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, rejected) => dispatch(updateCase(newCase, 6, null, rejected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CmfExtendedStep6);
