import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import IndicatorCell from '../shared/table/IndicatorCell';
import DateCell from '../shared/table/DateCell';

import { caseConstants } from '../../constants';
import { utils } from '../../util';
import { TablePagination } from '@material-ui/core';

const {
  numberOfAClSteps,
  numberOfSteps,
  numberOfCmfExtendedSteps,
  numberOfCmfSteps,
  numberOfCustomSteps,
  statuses,
  caseActions,
  caseAclActions,
  caseCmfActions,
  caseCmfExtendedActions,
  procedureFlows,
} = caseConstants;

const CasesTableMobile = ({ cases, onClick, withoutPagination }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getNumberFlowSteps = (flow) => {
    switch (flow) {
      case procedureFlows.CMF:
        return numberOfCmfSteps;
      case procedureFlows.CMF_EXTENDED:
        return numberOfCmfExtendedSteps;
      case procedureFlows.ONCOL:
        return numberOfCmfExtendedSteps;
      case procedureFlows.CUSTOM_EXTENDED:
        return numberOfCmfExtendedSteps;
      case procedureFlows.DOUBLE_LEVEL_OSTEOTOMY:
        return numberOfCmfExtendedSteps;
      case procedureFlows.ACL:
        return numberOfAClSteps;
      case procedureFlows.CUSTOM:
        return numberOfCustomSteps;
      default:
        return numberOfSteps;
    }
  };

  const getFlowActions = (flow) => {
    switch (flow) {
      case procedureFlows.CMF:
        return caseCmfActions;
      case procedureFlows.CMF_EXTENDED:
        return caseCmfExtendedActions;
      case procedureFlows.ONCOL:
        return caseCmfExtendedActions;
      case procedureFlows.CUSTOM_EXTENDED:
        return caseCmfExtendedActions;
      case procedureFlows.DOUBLE_LEVEL_OSTEOTOMY:
        return caseCmfExtendedActions;
      case procedureFlows.ACL:
        return caseAclActions;
      default:
        return caseActions;
    }
  };

  return (
    <div className="cases-table-mobile" style={{ marginBottom: !withoutPagination ? 64 : 0 }}>
      {cases
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item) => {
          const procedureFlow = item && item.procedureFlow;
          const numberFlowSteps = getNumberFlowSteps(procedureFlow);
          const actions = getFlowActions(procedureFlow);
          const action = procedureFlow === procedureFlows.ACL ? actions[item.step] : actions[item.step];
          const getFlowTheme = utils.getFlowsTheme(item?.procedureFlow, item.procedure).main;

          return (
            <div key={item.id} className="case-mobile-row" onClick={() => onClick(item)}>
              <div className="d-flex space-between">
                <div className="d-flex m-r-md flex-1">
                  <IndicatorCell
                    value={item.access}
                    complete={item.step > numberFlowSteps}
                    rejected={item.status === statuses.rejected}
                    archived={!!item.archived}
                    postponed={!!item.postponed}
                    expiry={item?.createdAt ? item?.createdAt : false}
                    style={getFlowTheme}
                  />
                  <div className="m-l-md">{item?.formattedId || item.id}</div>
                </div>
                <div className="d-flex flex-1 flex-end">
                  <DateCell date={item.date} />
                </div>
              </div>
              <div className="m-t-sm">
                {item.hospital}
              </div>
              <div className="m-t-sm">
                {item.procedureName}
              </div>
              <div className="d-flex space-between m-t-sm">
                <div style={{ color: getFlowTheme }}
                     className={`m-r-md flex-1 ${item.access ? 'primary' : 'secondary'}`}>
                  {action || ''}
                </div>
                <div className="d-flex flex-end">
                  <span
                    style={{ color: getFlowTheme }}>{item.step > numberFlowSteps ? numberFlowSteps : item.step}</span>
                  /{numberFlowSteps}
                </div>
              </div>
            </div>
          );
        })}
      {!withoutPagination && (
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={cases.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

CasesTableMobile.propTypes = {
  cases: PropTypes.array,
  onClick: PropTypes.func,
};

export default memo(CasesTableMobile);
