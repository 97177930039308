import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';

import Autocomplete from '../../shared/Autocomplete';

const NewUser = ({ newUser, userList, onChange, onCancel, onSubmit, isSubItem }) => {
  const filteredUserList = userList.filter((user) => !user.role);

  return (
    <div className="admin-modal-item new-item">
      <div className="column">
        <div className={isSubItem ? 'sub-item-circle-new' : 'modal-item-circle light'} />
      </div>
      <div className="modal-item-content d-flex user-select">
        <Hidden xsDown implementation="css">
          <Autocomplete
            onChange={(event, value) => onChange(value)}
            value={newUser}
            options={filteredUserList.sort((a, b) => a.lastName.localeCompare(b.lastName))}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            groupBy={(option) => option?.lastName[0]?.toUpperCase() || option?.firstName[0]?.toUpperCase()}
          />
        </Hidden>
        <Hidden smUp implementation="css" style={{ width: '100% !important' }}>
          <Select
            id="user-select"
            className="user-select"
            onChange={(e) => onChange(e.target.value)}
          >
            {filteredUserList.map((item) => (
              <MenuItem key={item.uid} value={item}>
                {item.firstName} {item.lastName}
              </MenuItem>
            ))}
          </Select>
        </Hidden>
        <div className="p-l-md primary pointer" onClick={onSubmit}>
          Add
        </div>
        <div className="p-l-md secondary pointer" onClick={onCancel}>
          Cancel
        </div>
      </div>
    </div>
  );
};

NewUser.propTypes = {
  newUser: PropTypes.object,
  userList: PropTypes.array,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isSubItem: PropTypes.bool,
};

export default NewUser;
