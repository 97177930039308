import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import moment from 'moment';

import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import MechanicalAxis from '../MechanicalAxis';
import ConsultationFileReview from './ConsultationFileReview';
import HtoCoronal from '../hto-flow/HtoCoronal';
import HtoSagittal from '../hto-flow/HtoSagittal';
import { DfoCoronal } from '../dfo-flow/DfoCoronal';
import { DfoSagittal } from '../dfo-flow/DfoSagittal';
import HtoSummary from '../hto-flow/HtoSummary';
import DfoSummary from '../dfo-flow/DfoSummary';

import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';
import AlertModal from '../../../../AlertModal';
import Button from '../../../../../shared/Button';
import { NoProcedureFlow } from '../../../../../shared/NoProcedureFlow';

import { api } from '../../../../../../util';

import { caseConstants, urls } from '../../../../../../constants';
import { roleNames } from '../../../../../../constants/userRoles';
import { fileTypes } from '../../../../../../constants/cases';

const delay = 1000;

const { GLOBAL_ADMIN, COUNTRY_ADMIN } = roleNames;

const OsteotomyStep4 = ({ onClose, activeCase, updateCase, userRole, classes, userId }) => {
  const { alignmentData } = activeCase;
  const { procedureFlows, alignmentDataTypes } = caseConstants;

  const initialTibialWidth = Number(((alignmentData?.['WBL'] / alignmentData?.['TW']) * 100).toFixed(1));
  const initialPTS = alignmentData?.['PPTA'] ? (900 - alignmentData?.['PPTA'] * 10) / 10 : 0;

  const [view, setView] = useState(0);
  const [loading, toggleLoading] = useState(false);
  const [summaryView, toggleSummaryView] = useState(!!alignmentData?.measurementsRejected);

  const [DCA, setDCA] = useState(0);
  const [tibialWidth, setTibialWidth] = useState(50);
  const [RCA, setRCA] = useState(alignmentData?.['Pre-Op_HKA'] || 0);
  const [PTS, setPTS] = useState(initialPTS);

  const [WH, setWH] = useState(0);
  const [wedgePosterior, setWedgePosterior] = useState(0);
  const [wedgeAnterior, setWedgeAnterior] = useState(0);
  const [wedgeIncluding, setWedgeIncluding] = useState(
    alignmentData?.type === alignmentDataTypes.varus ? 'include' : 'remove'
  );

  const [fujisawaLoading, toggleFujisawaLoading] = useState(false);
  const [DFPLoading, toggleDFPLoading] = useState(false);
  const [overallCorrectionLoading, toggleOverallCorrectionLoading] = useState(false);

  const [open, toggleModal] = useState(false);
  const [skipModalOpen, toggleSkipModal] = useState(false);

  useEffect(() => {
    if (activeCase?.files?.find((f) => f.type === fileTypes.alignmentAnalysis)) {
      setView('consultation');
    }
  }, [activeCase?.files]);

  useEffect(() => {
    calculateFujisawa(50);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDCAChange = (value) => {
    setDCA(value);
    calculateDesiredFinishPoint(value);
  };

  const handleTibialWidthChange = (value) => {
    setTibialWidth(value);
    calculateFujisawa(value);
  };

  const handleRCAChange = (value) => {
    setRCA(value);
    calculateOverallCorrection(value);
  };

  const calculateFujisawa = useCallback(
    debounce((ptwPost) => {
      toggleFujisawaLoading(true);

      const tw = alignmentData['TW'];
      const jla = alignmentData['JL_A'];
      const twjla = alignmentData['TW_JL_A'];
      const jlcofh = alignmentData['JL_CoFH'];
      const twjlcofh = alignmentData['TW_JL_CoFH'];
      const preop = alignmentData['Pre-Op_HKA'];

      api.post(urls.calculation.fujisawa, { ptwPost, tw, jla, twjla, jlcofh, twjlcofh, preop, procedureFlow: activeCase.procedureFlow }).then((res) => {
        if (res && res.body) {
          const result = JSON.parse(res.body);

          if (res.statusCode === 500) {
            console.log(result.error);
          } else {
            const { dca, rca } = result;

            setDCA(+dca.toFixed(1));
            setRCA(+rca.toFixed(1));
          }
        }
        toggleFujisawaLoading(false);
      });
    }, delay),
    []
  );

  const calculateDesiredFinishPoint = useCallback(
    debounce((dca) => {
      toggleDFPLoading(true);

      const tw = alignmentData['TW'];
      const jla = alignmentData['JL_A'];
      const twjla = alignmentData['TW_JL_A'];
      const jlcofh = alignmentData['JL_CoFH'];
      const twjlcofh = alignmentData['TW_JL_CoFH'];
      const preop = alignmentData['Pre-Op_HKA'];

      api.post(urls.calculation.desiredFinishPoint, { dca, tw, jla, twjla, jlcofh, twjlcofh, preop }).then((res) => {
        if (res && res.body) {
          const result = JSON.parse(res.body);

          if (res.statusCode === 500) {
            console.log(result.error);
          } else {
            const { rca, ptwPost } = result;

            setRCA(+rca.toFixed(1));
            setTibialWidth(+ptwPost.toFixed(1));
          }
        }
        toggleDFPLoading(false);
      });
    }, delay),
    []
  );

  const calculateOverallCorrection = useCallback(
    debounce((rca) => {
      toggleOverallCorrectionLoading(true);

      const tw = alignmentData['TW'];
      const jla = alignmentData['JL_A'];
      const twjla = alignmentData['TW_JL_A'];
      const jlcofh = alignmentData['JL_CoFH'];
      const twjlcofh = alignmentData['TW_JL_CoFH'];
      const preop = alignmentData['Pre-Op_HKA'];

      api
        .post(urls.calculation.overallCorrection, { rca, tw, jla, twjla, jlcofh, twjlcofh, preop })
        .then((res) => {
          if (res && res.body) {
            const result = JSON.parse(res.body);

            if (res.statusCode === 500) {
              console.log(result.error);
            } else {
              const { dca, ptwPost } = result;

              setDCA(+dca.toFixed(1));
              setTibialWidth(+ptwPost.toFixed(1));
            }
          }
          toggleOverallCorrectionLoading(false);
        })
        .catch((error) => {
          console.log(error);
          toggleOverallCorrectionLoading(false);
        });
    }, delay),
    []
  );

  const getOsteotomyByFlow = (flow) => {
    switch (flow) {
      case procedureFlows.HTO:
        return alignmentData['TW_Osteotomy'];
      case procedureFlows.HTO_LATERAL:
        return alignmentData['TW_Osteotomy'];
      case procedureFlows.DFO_Lateral:
        return alignmentData['FW_Osteotomy'];
      case procedureFlows.DFO_Medial:
        return alignmentData['FW_Osteotomy'];
      default:
        return alignmentData['TW_Osteotomy'];
    }
  };

  const handleSubmit = () => {
    const two = getOsteotomyByFlow(activeCase.procedureFlow);

    toggleLoading(true);
    api
      .post(urls.calculation.wedgeMeasurements, { two, rca: RCA })
      .then((res) => {
        if (res && res.body) {
          const result = JSON.parse(res.body);

          if (res.statusCode === 500) {
            console.log(result.error);
          } else {
            const { wh, posterior, anterior } = result;

            setWH(+wh.toFixed(1));
            setWedgePosterior(posterior);
            setWedgeAnterior(anterior);
            toggleSummaryView(true);
          }
        }
        toggleLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toggleLoading(false);
      });
  };

  const handleConfirm = () => {
    const now = moment().format();
    const activity = [{ ...caseConstants.caseNotifications[4].success, date: now, userId }, ...activeCase.activity];

    const updatedData = {
      ...alignmentData,
      '%WBL': initialTibialWidth,
      D_CA: DCA,
      Tibial_Width: tibialWidth,
      R_CA: RCA,
      Pre_TS: initialPTS,
      Post_TS: PTS,
      WH: WH,
      Wedge_posterior: wedgePosterior,
      Wedge_anterior: wedgeAnterior,
      wedgeIncluding: wedgeIncluding === 'include' || alignmentData.type === alignmentDataTypes.valgus,
    };

    toggleModal(false);
    updateCase({
      ...activeCase,
      step: 5,
      activity,
      completionDates: { ...activeCase.completionDates, step4: now },
      completedBy: { ...activeCase.completedBy, step4: userId },
      alignmentData: updatedData,
      status: caseConstants.statuses.inProgress,
    }).then(() => onClose());
  };

  const renderView = (view, flow) => {
    if (view === 'consultation') {
      return (
        <ConsultationFileReview
          activeCase={activeCase}
          classes={classes}
        />
      );
    }

    if (view === 0) {
      return (
        <MechanicalAxis activeCase={activeCase} loading={loading} initialTibialWidth={initialTibialWidth} PTS={PTS} />
      );
    }

    if (view === 1 && (flow === procedureFlows.HTO || flow === procedureFlows.HTO_LATERAL)) {
      return (
        <HtoCoronal
          activeCase={activeCase}
          DCA={DCA}
          tibialWidth={tibialWidth}
          initialTibialWidth={initialTibialWidth}
          RCA={RCA}
          handleDCAChange={handleDCAChange}
          handleTWChange={handleTibialWidthChange}
          handleRCAChange={handleRCAChange}
          fujisawaLoading={fujisawaLoading}
          DFPLoading={DFPLoading}
          overallCorrectionLoading={overallCorrectionLoading}
          loading={loading}
        />
      );
    }

    if (view === 1 && (flow === procedureFlows.DFO_Lateral || flow === procedureFlows.DFO_Medial)) {
      return (
        <DfoCoronal
          activeCase={activeCase}
          DCA={DCA}
          tibialWidth={tibialWidth}
          initialTibialWidth={initialTibialWidth}
          RCA={RCA}
          handleDCAChange={handleDCAChange}
          handleTWChange={handleTibialWidthChange}
          handleRCAChange={handleRCAChange}
          fujisawaLoading={fujisawaLoading}
          DFPLoading={DFPLoading}
          overallCorrectionLoading={overallCorrectionLoading}
          loading={loading}
        />
      );
    }

    if (view === 2 && (flow === procedureFlows.HTO || flow === procedureFlows.HTO_LATERAL)) {
      return (
        <HtoSagittal
          activeCase={activeCase}
          PTS={PTS}
          handlePTSChange={setPTS}
          initialPTS={initialPTS}
          loading={loading}
          skipModalOpen={skipModalOpen}
          toggleSkipModal={toggleSkipModal}
          handleSubmit={handleSubmit}
        />
      );
    }

    if (view === 2 && (flow === procedureFlows.DFO_Lateral || flow === procedureFlows.DFO_Medial)) {
      return (
        <DfoSagittal
          activeCase={activeCase}
          PTS={PTS}
          handlePTSChange={setPTS}
          initialPTS={initialPTS}
          loading={loading}
          skipModalOpen={skipModalOpen}
          toggleSkipModal={toggleSkipModal}
          handleSubmit={handleSubmit}
        />
      );
    }
  };

  const renderSummaryView = (flow) => {
    if (flow === procedureFlows.HTO || flow === procedureFlows.HTO_LATERAL) {
      return (
        <HtoSummary
          classes={classes}
          type={alignmentData.type}
          HKA={alignmentData['Pre-Op_HKA']}
          initialTibialWidth={initialTibialWidth}
          DCA={DCA}
          tibialWidth={tibialWidth}
          RCA={RCA}
          initialPTS={initialPTS}
          PTS={PTS}
          WH={WH}
          wedgePosterior={wedgePosterior}
          wedgeAnterior={wedgeAnterior}
          measurementsRejected={alignmentData.measurementsRejected}
          notes={activeCase.notes && activeCase.notes.step5}
        />
      );
    }

    if (flow === procedureFlows.DFO_Lateral || flow === procedureFlows.DFO_Medial) {
      return (
        <DfoSummary
          classes={classes}
          type={alignmentData.type}
          flow={activeCase.procedureFlow}
          HKA={alignmentData['Pre-Op_HKA']}
          initialTibialWidth={initialTibialWidth}
          DCA={DCA}
          tibialWidth={tibialWidth}
          RCA={RCA}
          initialPTS={initialPTS}
          PTS={PTS}
          WH={WH}
          wedgePosterior={wedgePosterior}
          wedgeAnterior={wedgeAnterior}
          measurementsRejected={alignmentData.measurementsRejected}
          notes={activeCase.notes && activeCase.notes.step5}
        />
      );
    }
  };

  return (
    <div className="case-step-container">
      {activeCase.access ? (
        <div className="case-step-content">
          <div className="case-step-row step-4 height-100">
            {summaryView ? (
              <div className="modal-item-content">
                {renderSummaryView(activeCase.procedureFlow)}

                {alignmentData.type === alignmentDataTypes.varus && (
                  <Fragment>
                    <div className="secondary font-size-xs">
                      *The Allograft Wedge will be assigned to this surgery, unless removed below
                    </div>
                    <div className="radio-group-container d-flex flex-end">
                      <RadioGroup
                        aria-label="calculationType"
                        name="calculationType"
                        value={wedgeIncluding}
                        onChange={(e) => setWedgeIncluding(e.target.value)}
                      >
                        <FormControlLabel value="include" control={<Radio color="primary" />} label="Include" />
                        <FormControlLabel value="remove" control={<Radio color="primary" />} label="Remove" />
                      </RadioGroup>
                    </div>
                  </Fragment>
                )}
              </div>
            ) : (
              <div className="modal-item-content d-flex space-between height-100">
                {activeCase.procedureFlow && renderView(view, activeCase.procedureFlow)}

                {!activeCase.procedureFlow && <NoProcedureFlow procedureName={activeCase.procedureName} />}
              </div>
            )}
          </div>
          {(userRole === GLOBAL_ADMIN || userRole === COUNTRY_ADMIN) && <CaseAccordion currentStep={4} onClose={onClose} />}
        </div>
      ) : (
        <div className="case-step-content">
          <NoAccess />
          <CaseAccordion currentStep={4} onClose={onClose} />
        </div>
      )}
      <div className="case-modal-actions">
        {summaryView ? (
          <Fragment>
            <div className="secondary pointer" onClick={onClose}>
              Close
            </div>
            <div className="step-4-buttons">
              <Button
                text="Back"
                type="secondary"
                color="primary"
                disabled={loading || !activeCase.access}
                onClick={() => toggleSummaryView(false)}
              />
              <Button text="Confirm" color="primary" onClick={() => toggleModal(true)} disabled={!activeCase.access} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="secondary pointer" onClick={onClose}>
              Close
            </div>
            <div className="step-4-buttons">
              {(view === 0 || view === 1 || view === 2) && (
                <Button
                  text="Back"
                  type="secondary"
                  color="primary"
                  disabled={loading || !activeCase.access}
                  onClick={() => {
                    if (view === 0) {
                      setView('consultation')
                    } else {
                      setView(view - 1)
                    }
                  }}
                />
              )}
              {view === 2 ? (
                <Button
                  text="Submit"
                  type="submit"
                  color="primary"
                  disabled={loading || !activeCase.access}
                  loading={loading}
                  onClick={handleSubmit}
                />
              ) : (
                <Button
                  text="Next"
                  type="submit"
                  color="primary"
                  disabled={loading || !activeCase.access || !activeCase.procedureFlow}
                  loading={loading}
                  onClick={() => {
                    if (view === 'consultation') {
                      setView(0);
                    } else if (view === 1) {
                      setView(2);
                      toggleSkipModal(true);
                    } else {
                      setView(view + 1);
                    }
                  }}
                />
              )}
            </div>
          </Fragment>
        )}
      </div>
      <AlertModal
        open={open}
        onClose={() => toggleModal(false)}
        onSubmit={handleConfirm}
        title="Are you sure you want to submit these values for surgical planning?"
        text="Confirming these values will pass the data to the case engineer for final Pre-op planning"
      />
    </div>
  );
};

export default OsteotomyStep4;
